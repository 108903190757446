import { useOutletContext } from 'react-router-dom';
import CallLog from './CallLog/CallLog';

const Dashboard = () => {
    const { triggerNotification, setTriggerNotification } = useOutletContext() as any;

    const resetNotificationTrigger = () => {
        setTriggerNotification(false);
    };

    return (
        <div style={{ display: "flex" }}>
            <CallLog
                triggerNotification={triggerNotification}
                resetNotificationTrigger={resetNotificationTrigger}
            />
        </div>
    );
};

export default Dashboard;