import { Button, MenuItem, Modal, Tab, Tabs, TextField, Tooltip } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import styles from '../../components/Table/Table.module.scss';
import axios from "../../apis/axios";
import { toast } from "react-toastify";
import CloseButton from "../../components/Modal/CloseButton/CloseButton";
import AdvancedDropDown from "../../components/AdvancedDropDown/AdvancedDropDown";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import he from 'he';
import { Chip } from "../../components";
import Swal from "sweetalert2";


const MessagingTemplates = (props) => {

    const [columns] = useState([
        {
          Header: "Reference",
          accessor: "ref",
        },
        {
          Header: "Subject",
          accessor: "subject",
        },
        {
          Header: "Email Message",
          accessor: "email_msg",
        },
        {
            Header: "SMS Message",
            accessor: "sms_msg",
        },
        {
            Header: "Template Type",
            accessor: "type",
        },
        {
            Header: "Main Entity",
            accessor: "main_entity",
        },
        {
            Header: "Active",
            accessor: "isActive",
        }        
    ])

    const [data, setData] = useState([]) as any
    const [showStaticModal, setShowStaticModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [numOfRecords, setNumOfRecords] = useState(0)


    async function getTemplates() {
        await axios.get(`${process.env.REACT_APP_TEXT_API_URL}Template/GetTemplatesByCategory?Category=S`).then((result) => {
            if (result.status === 200) {
                if(result?.data === "No data found"){
                    setData([])
                }
                else{
                    let tempData = [] as any

                    result?.data.map(template => {
                        const tempArr = {
                            id: template.ID,
                            ref: template.TemplateReference,
                            subject: template.Subject,
                            email_msg: template.EmailMessage,
                            sms_msg: template.SMSMessage,
                            type: (template.TemplateTypeID === 1) ? "Static" : "Dynamic",
                            response_expected: (template.responseExpected === 1) ? "Yes" : "No",
                            main_entity: template.TargetEntity,
                            isActive: template.IsActive,
                            TemplateCategory: template.TemplateCategory
                        }                
    
                        tempData.push(tempArr)
                    })

                    setNumOfRecords(tempData.length)
                    setData(tempData)
                    
                }            
            }
        }).catch((err) => {
            console.log(err);
            toast.error('Something went wrong!');
        })
    }

    useEffect(() => {
        getTemplates()
    }, [])

    const [subject, setSubject] = useState("")
    const [templateRef, setTemplateRef] = useState("")
    const [selectParameter, setSelectParameter] = useState("")
    const [selectParameterEdit, setSelectParameterEdit] = useState("")
    const [htmlBody, setHtmlBody] = useState("")
    const [mainEntity, setMainEntity] = useState("Appointment")
    const [mainEntityEdit, setMainEntityEdit] = useState("")

    const [templateIDEdit, setTemplateIDEdit] = useState("")
    const [htmlBodyEdit, setHtmlBodyEdit] = useState("")
    const [templateRefEdit, setTemplateRefEdit] = useState("")
    const [subjectEdit, setSubjectEdit] = useState("")
    const [smsBodyEdit, setSmsBodyEdit] = useState("")
    const [templateTypeEdit, setTemplateTypeEdit] = useState(0)
    const [isActiveEdit, setIsActiveEdit] = useState(1)

    const [editedParam, setEditedParam] = useState("")
    const [editedParamEdit, setEditedParamEdit] = useState("")
    const [templateType, setTemplateType] = useState(1)

    const [smsBody, setSmsBody] = useState("")
    const [parameterList, setParameterList] = useState([]) as any

    const parameterRef = useRef<any>();
    const parameterRefEdit = useRef<any>();

    async function postTemplate(data) {
        await axios.post(`${process.env.REACT_APP_TEXT_API_URL}Template/PopulateTemplate`, 
        JSON.stringify(data),
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then((result) => {
            if (result.status === 200) {
                toast.success(`Template Creation Successful!`)

                setTemplateRef("")
                setHtmlBody("")
                setTemplateRef("")
                setSmsBody("")
                setSubject("")
                getTemplates()   
                parameterRef.current?.handleClear();
                setEditorHtml("")                
            

                setShowStaticModal(false)
            }
        }).catch((err) => {
            console.log(err);
            toast.error(err.response.data.message);
        })
    }

    async function putTemplate(data) {
        await axios.put(`${process.env.REACT_APP_TEXT_API_URL}Template`, 
        JSON.stringify(data),
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then((result) => {
            if (result.status === 200) {
                toast.success(`Template Update Successful!`)

                setTemplateRefEdit("")
                setHtmlBodyEdit("")
                setTemplateRefEdit("")
                setSmsBodyEdit("")
                setSubjectEdit("")
                getTemplates()   
                parameterRefEdit.current?.handleClear(); 
                setEditorHtmlEdit("")                
                setShowEditModal(false)
              
            }
        }).catch((err) => {
            console.log(err);
            // toast.error('Something went wrong!');
        })
    }

    const createTemplate = () => {
        let templateTypeTemp = 0
        if (hasParameters(editorHtml)) {
            setTemplateType(2)
            templateTypeTemp = 2
        } else {
            setTemplateType(1)
            templateTypeTemp = 1
        }        
        let body = deduceParameters(editorHtml) as any
        body.templateID = "0"
        body.templateReference = templateRef
        body.subject = subject
        body.emailMessage = editorHtml
        body.smsMessage = htmlToPlainText(smsBody)
        body.templateTypeID = templateTypeTemp
        body.isActive = true
        body.status = "I"
        body.targetEntity = mainEntity
        body.templateCategory = "S"

        console.log("post new sent", body)
        postTemplate(body)
    }

    async function getParameters(type) {
        await axios.get(`${process.env.REACT_APP_TEXT_API_URL}Template/GetDynamicParametersByEntityName?EntityName=${type}`).then((result) => {
            if (result.status === 200) {
                //setParameterList(result?.data)
                console.log("paramList", result?.data)
                let paramList = result?.data;
                if(mainEntity === "Appointment"){
                    console.log("paramList here1" )
                    paramList.push(
                        {
                            "ID": "Vaccines.AllVaccines",
                            "ParameterName": "AllVaccines",
                            "TargetEntity": "Vaccines",
                            "FieldName": "AllVaccines"
                        },
                        {
                            "ID": "Vaccines.Unexpired",
                            "ParameterName": "Unexpired",
                            "TargetEntity": "Vaccines",
                            "FieldName": "Unexpired"
                        },
                        {
                            "ID": "Vaccines.ExpiredVaccines",
                            "ParameterName": "ExpiredVaccines",
                            "TargetEntity": "Vaccines",
                            "FieldName": "ExpiredVaccines"
                        }
                    )
                    setParameterList(paramList)
                    console.log("params", paramList)
                }else{
                    console.log("paramList here2" )
                    setParameterList(result?.data)
                }
             
            }
        }).catch((err) => {
            console.log(err);
            toast.error('Something went wrong!');
        })
    }
    const getDefualtTemplate = async (id) => {
        const apiUrl = `${process.env.REACT_APP_TEXT_API_URL}Template/GetDefaultTemplateByID?TemplateID=${id}`
    
        try {
            const response = await axios.get(apiUrl, {})
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }


    useEffect(() => {
        setEditedParamEdit(`{{${parameterList[selectParameterEdit]?.TargetEntity}.${parameterList[selectParameterEdit]?.ParameterName}}}`)
    }, [selectParameterEdit])

    useEffect(() => {
        setEditedParam(`{{${parameterList[selectParameter]?.TargetEntity}.${parameterList[selectParameter]?.ParameterName}}}`)
    }, [selectParameter])


    const editTemplate = (templateID, index) => {

        console.log(templateID, index)                    

        setTemplateIDEdit(templateID)
        setTemplateRefEdit(data[index].ref)
        setSubjectEdit(data[index].subject)
        setHtmlBodyEdit(data[index].email_msg)
        setEditorHtmlEdit(data[index].email_msg)
        setSmsBodyEdit(data[index].sms_msg)
        setTemplateTypeEdit((data[index].type === "Dynamic") ? 2 : 1)
        setIsActiveEdit((data[index].isActive)? 1 : 2)
        setMainEntityEdit(data[index].main_entity)
         setShowEditModal(true)
    }

    const saveEditedTemplate = () => {
        let templateTypeTemp = 0
    
        if (hasParameters(editorHtmlEdit)) {
            setTemplateTypeEdit(2)
            templateTypeTemp = 2
        } else {
            setTemplateTypeEdit(1)
            templateTypeTemp = 1
        }

        let editBody = deduceParameters(editorHtmlEdit) as any

        editBody.templateID = templateIDEdit.toString()
        editBody.templateReference = templateRefEdit
        editBody.subject = subjectEdit
        editBody.emailMessage = editorHtmlEdit
        editBody.smsMessage = htmlToPlainText(smsBodyEdit)
        editBody.templateTypeID = templateTypeTemp
        editBody.isActive = (isActiveEdit === 1) ? true : false 
        editBody.status = "U"
        editBody.targetEntity = mainEntityEdit
        editBody.templateCategory = "S"

        console.log("edit body - sent", editBody)
        putTemplate(editBody)
    }

    const deduceParameters = (finalBody) => {
        let body = {
            "templateID": null,
            "templateReference": null,
            "subject": null,
            "emailMessage": null,
            "smsMessage": null,
            "templateTypeID": null,        
            "isActive": null,
            "status":  null,
            "targetEntity":  ""
        }
        return body
    }

    const hasParameters = (finalBody) => {
        if (finalBody.includes("{{") && finalBody.includes("}}")) {
            return true
        } else {
            return false
        }
    }

    const [tabValue, setTabValue] = useState(0);
    const [createNewTab, setCreateNewTab] = useState(0);
    const [editTab, setEditTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleNewTabChange = (event, newValue) => {
        setCreateNewTab(newValue);
    };

    const handleEditTabChange = (event, newValue) => {
        setEditTab(newValue);
    };

    /////////////////////////////////////////////////////////////
    const [editorHtml, setEditorHtml] = useState('');
    const [editorHtmlEdit, setEditorHtmlEdit] = useState('');
    const quillRef = useRef(null) as any
    const quillRefEdit = useRef(null) as any
    const quillRefSms = useRef(null) as any
    const quillRefSmsEdit = useRef(null) as any

    const handleChange = (html) => {
      setEditorHtml(html);
    }
    const handleChangeSMS = (html) => {
        setSmsBody(html);
    }
    const handleChangeEdit = (html) => {
        setEditorHtmlEdit(html);
    }
    const handleChangeSMSEdit = (html) => {
        setSmsBodyEdit(html);
    }


    const modules = {
        toolbar: [
          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
          [{ 'size': [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
          ['link', 'image', 'video'],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'align': [] }],
          ['code-block'],
          ['clean']
        ],
      };

      const appendParameter = () => {
        if (quillRef.current) {
          const editor = quillRef.current.getEditor();
          const cursorPosition = editor.getSelection(true).index;
          editor.insertText(cursorPosition, editedParam);
        }
      };

      const appendParameterSMS = () => {
        if (quillRefSms.current) {
          const editor = quillRefSms.current.getEditor();
          const cursorPosition = editor.getSelection(true).index;
          editor.insertText(cursorPosition, editedParam);
        }
      };

      const appendParameterEdit = () => {
        if (quillRefEdit.current) {
          const editor = quillRefEdit.current.getEditor();
          const cursorPosition = editor.getSelection(true).index;
          editor.insertText(cursorPosition, editedParamEdit);
        }
      };

      const appendParameterSMSEdit = () => {
        if (quillRefSmsEdit.current) {
          const editor = quillRefSmsEdit.current.getEditor();
          const cursorPosition = editor.getSelection(true).index;
          editor.insertText(cursorPosition, editedParamEdit);
        }
      };

      const SMSmodules = {
        toolbar: false // Disable the toolbar
      };

      
    function htmlToPlainText(html) {
        let plainText = html.replace(/<[^>]+>/g, '');
        plainText = he.decode(plainText);
    
        return plainText;
    }

    useEffect(()=>{
        setSelectParameter("")
        parameterRef.current?.handleClear();
    },[createNewTab])

    useEffect(()=>{
        setSelectParameterEdit("")
        parameterRefEdit.current?.handleClear();
    },[editTab])

    useEffect(()=>{
        getParameters(mainEntity)
    },[mainEntity])

    const revertOnClick = (type) => {

        Swal.fire({
            title: 'Are you sure?',
            text: 'Your current changes will be lost!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, revert it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.isConfirmed) {

                getDefualtTemplate(templateIDEdit)
                .then((data) => {
                    if(type === "Email"){
                        setEditorHtmlEdit(data[0].EmailMessage)
                    }else{
                        setSmsBodyEdit(data[0].SMSMessage)
                    }
                  })
                  .catch((error) => {
                      console.log("error", error)
                  })

              // Handle delete action here
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
          });
    }

    return (
        <div style={{ padding: '0 1.5rem' }}>
            <h2>Messaging Templates</h2>
            {
                (showStaticModal) ? 
                    <Modal open={showStaticModal} >
                        <div style={{display: 'flex', justifyContent: 'center', flexDirection:'column', alignItems: 'center', gap: '1rem', padding: '10px', height: '100vh' }}>                                   
                            <div style={{backgroundColor: '#fff', width: '1200px', borderRadius: '15px', padding: '20px' }}>  
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: '10px'}}>                            
                                    <p style={{ width: '100%', fontWeight: '600', fontSize: '16px' }}>New Template</p>
                                    <CloseButton onClick={() => setShowStaticModal(false)}/>
                                </div>  
                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                                    <TextField
                                        style={{ marginBottom: '20px', flex: 1 }}
                                        size="small"
                                        onChange={(e) => setTemplateRef(e.target.value)}
                                        variant={'filled'}
                                        fullWidth
                                        label={
                                            <div style={{ display: 'flex', fontSize: '14px' }}>
                                                Template Reference
                                                {(true) ?
                                                    <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                    : null}
                                            </div>
                                        }
                                        required={true}
                                        inputProps={{
                                            pattern: "^[a-zA-Z ]*$"
                                        }}    
                                        value={templateRef}
                                    ></TextField>

                                    <TextField
                                        style={{ flex: 1 }}
                                        size="small"
                                        onChange={(e) => setSubject(e.target.value)}
                                        variant={'filled'}
                                        fullWidth
                                        label={
                                            <div style={{ display: 'flex', fontSize: '14px' }}>
                                                Subject
                                                {(true) ?
                                                    <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                    : null}
                                            </div>
                                        }
                                        required={true}
                                        inputProps={{
                                            pattern: "^[a-zA-Z ]*$"
                                        }}    
                                        value={subject}
                                    ></TextField>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                                    <div style={{ flex: 1 }}>
                                        <TextField 
                                            onChange={(e) => setMainEntity(e.target.value)}
                                            select={true}
                                            variant={'filled'}
                                            fullWidth
                                            label={
                                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                                    Main Entity
                                                    {(true) ?
                                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                        : null}
                                                </div>
                                            }
                                            required={true}
                                            inputProps={{
                                                pattern: "^[a-zA-Z ]*$"
                                            }}                            
                                            value={mainEntity}
                                        >
                                            <MenuItem value={"Appointment"}>Appointments</MenuItem>
                                            <MenuItem value={"Customer"}>Customer</MenuItem>
                                            <MenuItem value={"Employee"}>Employee</MenuItem>
                                            <MenuItem value={"Pet"}>Pet</MenuItem>
                                            <MenuItem value={"AppLink"}>AppLink</MenuItem>
                                        </TextField>
                                    </div>
                                </div>

                                <div>
                                    <Tabs value={createNewTab} onChange={handleNewTabChange}>
                                        <Tab label="Email" />
                                        <Tab label="SMS" />
                                    </Tabs>
                                    {/* Content for the Email tab */}
                                    {createNewTab === 0 && (
                                        <div style={{marginTop:"10px"}}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', flex: 1 }}>
                                                {
                                                    (parameterList !== undefined && parameterList !== null) ?
                                                        <div style={{ width: '100%' }}>
                                                            <AdvancedDropDown
                                                                data={parameterList.map((i, index) => ({ label: i.TargetEntity + "." + i.ParameterName, value: index }))}
                                                                ref={parameterRef}
                                                                placeHolder={"Select Parameter"}
                                                                value={selectParameter}
                                                                passByID={true}
                                                                onChange={(e) => { setSelectParameter(e.value) }}
                                                            />
                                                        </div>
                                                    : null
                                                }
                                                <Button variant="contained" onClick={() =>{appendParameter()}}>Insert&nbsp;Field</Button>
                                            </div>
                                            <div style={{ flex: 2 }}>
                                                <h3 style={{ marginBottom: '10px' }}>Email Body</h3>

                                                <div style={{ backgroundColor: '#fff', border: '1px solid gray' }}>       
                                                    <ReactQuill
                                                        theme="snow"
                                                        modules={modules}
                                                        value={editorHtml}
                                                        onChange={handleChange}
                                                        ref={quillRef}
                                                    />                       
                                                </div>     
                                            </div>
                                        </div>
                                    )}
                                    {/* Content for the SMS tab */}
                                    {createNewTab === 1 && (
                                        <div style={{marginTop:"10px"}}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', flex: 1 }}>
                                                {
                                                    (parameterList !== undefined && parameterList !== null) ?
                                                        <div style={{ width: '100%' }}>
                                                            <AdvancedDropDown
                                                                data={parameterList.map((i, index) => ({ label: i.TargetEntity + "." + i.ParameterName, value: index }))}
                                                                ref={parameterRef}
                                                                placeHolder={"Select Parameter"}
                                                                value={selectParameter}
                                                                passByID={true}
                                                                onChange={(e) => { setSelectParameter(e.value) }}
                                                            />
                                                        </div>
                                                    : null
                                                }
                                                <Button variant="contained" onClick={() =>{appendParameterSMS()}}>Insert&nbsp;Field</Button>
                                            </div>
                                            <div style={{ flex: 2 }}>
                                                <h3 style={{ marginBottom: '10px' }}>SMS Body</h3>

                                                <div style={{ backgroundColor: '#fff', border: '1px solid gray' }}>       
                                                    <ReactQuill
                                                        theme="snow"
                                                        modules={SMSmodules}
                                                        value={smsBody}
                                                        onChange={handleChangeSMS}
                                                        ref={quillRefSms}
                                                    />
                                                </div>     
                                            </div>
                                        </div>
                                    )}
                                    </div> 
                                <div style={{textAlign: 'end', width: '100%',marginTop: '20px' }}>
                                    <Button variant='contained' onClick={() => createTemplate()} color='primary'>Create Template</Button>
                                </div>        
                            </div>
                          
                        </div>                
                    </Modal>
                : null 
            }

            {
                (showEditModal) ? 
                    <Modal open={showEditModal} >
                        <div style={{display: 'flex', justifyContent: 'center', flexDirection:'column', alignItems: 'center', gap: '1rem', padding: '10px', height: '100vh'}}>                                   
                            <div style={{backgroundColor: '#fff', width: '1200px', borderRadius: '15px', padding: '20px' }}>  
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>                            
                                    <p style={{ width: '100%', fontWeight: '600', fontSize: '16px' }}>Edit Template</p>
                                    <CloseButton onClick={() => setShowEditModal(false)}/>
                                </div>  

                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                                <TextField
                                    style={{ marginBottom: '20px', flex: 1 }}
                                    onChange={(e) => setTemplateRefEdit(e.target.value)}
                                    variant={'filled'}
                                    fullWidth
                                    label={
                                        <div style={{ display: 'flex', fontSize: '14px' }}>
                                            Template Reference
                                            {(true) ?
                                                <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                : null}
                                        </div>
                                    }
                                    required={true}
                                    inputProps={{
                                        pattern: "^[a-zA-Z ]*$"
                                    }}    
                                    value={templateRefEdit}
                                ></TextField>

                                <TextField
                                    style={{ flex: 1 }}
                                    onChange={(e) => setSubjectEdit(e.target.value)}
                                    variant={'filled'}
                                    fullWidth
                                    label={
                                        <div style={{ display: 'flex', fontSize: '14px' }}>
                                            Subject
                                            {(true) ?
                                                <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                : null}
                                        </div>
                                    }
                                    required={true}
                                    inputProps={{
                                        pattern: "^[a-zA-Z ]*$"
                                    }}    
                                    value={subjectEdit}
                                ></TextField>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                                    <div style={{ flex: 1 }}>
                                        <TextField 
                                            onChange={(e) => setMainEntityEdit(e.target.value)}
                                            select={true}
                                            variant={'filled'}
                                            fullWidth
                                            label={
                                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                                    Main Entity
                                                    {(true) ?
                                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                        : null}
                                                </div>
                                            }
                                            required={true}
                                            inputProps={{
                                                pattern: "^[a-zA-Z ]*$"
                                            }}                            
                                            value={mainEntityEdit}
                                        >
                                            <MenuItem value={"Appointment"}>Appointments</MenuItem>
                                            <MenuItem value={"Customer"}>Customer</MenuItem>
                                            <MenuItem value={"Employee"}>Employee</MenuItem>
                                            <MenuItem value={"Pet"}>Pet</MenuItem>
                                            <MenuItem value={"AppLink"}>AppLink</MenuItem>
                                        </TextField>
                                    </div>                                        
                                </div>

                                <div>
                                    <Tabs value={editTab} onChange={handleEditTabChange}>
                                        <Tab label="Email" />
                                        <Tab label="SMS" />
                                    </Tabs>
                                    {/* Content for the Email tab */}
                                    {editTab === 0 && (
                                        <div style={{marginTop:"10px"}}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', flex: 1 }}>
                                                {
                                                    (parameterList !== undefined && parameterList !== null) ?
                                                    <div style={{ width: '100%' }}>
                                                        <AdvancedDropDown
                                                            data={parameterList.map((i, index) => ({ label: i.TargetEntity + "." + i.ParameterName, value: index }))}
                                                            ref={parameterRefEdit}
                                                            placeHolder={"Select Parameter"}
                                                            value={selectParameterEdit}
                                                            passByID={true}
                                                            onChange={(e) => { setSelectParameterEdit(e.value) }}
                                                        /> </div> : null
                                                }
                                                <Button variant="contained" onClick={() => {appendParameterEdit()}}>Insert&nbsp;Field</Button>
                                            </div>
                                            <div style={{ flex: 2 }}>
                                                
                                                <div style={{display:"flex", justifyContent:'space-between', alignItems:"center", marginTop:"10px"}}>
                                                    <h3 style={{ marginBottom: '10px' }}>Email Body</h3>
                                                    <Tooltip 
                                                        title={
                                                            <div >
                                                                <p style={{ fontSize: '14px', color: '#fff', fontWeight: '600' }}>Info</p>
                                                                <p style={{ fontSize: '12px', color: '#fff' }}>Revert to the preset template</p>
                                                            </div>                                                                                                                                                                                                                                                                   
                                                        }
                                                        >
                                                            <Button variant="outlined" color="success" onClick={() => {revertOnClick("Email")}}>Revert</Button>
                                                    </Tooltip>     
                                        
                                                </div>

                                                <div style={{ backgroundColor: '#fff', border: '1px solid gray' }}>       
                                                    <ReactQuill
                                                        theme="snow"
                                                        modules={modules}
                                                        value={editorHtmlEdit}
                                                        onChange={handleChangeEdit}
                                                        ref={quillRefEdit}
                                                    />
                                                </div>     
                                            </div>
                                        </div>
                                    )}
                                    {/* Content for the SMS tab */}
                                    {editTab === 1 && (
                                        <div style={{marginTop:"10px"}}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', flex: 1 }}>
                                                {
                                                    (parameterList !== undefined && parameterList !== null) ?
                                                    <div style={{ width: '100%' }}>
                                                        <AdvancedDropDown
                                                            data={parameterList.map((i, index) => ({ label: i.TargetEntity + "." + i.ParameterName, value: index }))}
                                                            ref={parameterRefEdit}
                                                            placeHolder={"Select Parameter"}
                                                            value={selectParameterEdit}
                                                            passByID={true}
                                                            onChange={(e) => { setSelectParameterEdit(e.value) }}
                                                        /> </div>: null
                                                }
                                                <Button variant="contained" onClick={() => {appendParameterSMSEdit()}}>Insert&nbsp;Field</Button>
                                            </div>
                                            <div style={{ flex: 2 }}>
                                            <div style={{display:"flex", justifyContent:'space-between', alignItems:"center", marginTop:"10px"}}>
                                                    <h3 style={{ marginBottom: '10px' }}>SMS Body</h3>
                                                    <Tooltip 
                                                        title={
                                                            <div >
                                                                <p style={{ fontSize: '14px', color: '#fff', fontWeight: '600' }}>Info</p>
                                                                <p style={{ fontSize: '12px', color: '#fff' }}>Revert to the preset template</p>
                                                            </div>                                                                                                                                                                                                                                                                   
                                                        }
                                                        >
                                                            <Button variant="outlined" color="success" onClick={() => {revertOnClick("SMS")}}>Revert</Button>
                                                    </Tooltip>     
                                        
                                                </div>  

                                                <div style={{ backgroundColor: '#fff', border: '1px solid gray' }}>       
                                                    <ReactQuill
                                                        theme="snow"
                                                        modules={SMSmodules}
                                                        value={smsBodyEdit}
                                                        onChange={handleChangeSMSEdit}
                                                        ref={quillRefSmsEdit}
                                                    />
                                                </div>     
                                            </div>
                                        </div>
                                    )}

                                            <TextField 
                                                onChange={(e) => setIsActiveEdit(parseInt(e.target.value))}
                                                select={true}
                                                variant={'filled'}
                                                fullWidth
                                                label={
                                                    <div style={{ display: 'flex', fontSize: '14px' }}>
                                                        Active/Inactive
                                                        {(true) ?
                                                            <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                            : null}
                                                    </div>
                                                }
                                                required={true}
                                                inputProps={{
                                                    pattern: "^[a-zA-Z ]*$"
                                                }}                            
                                                value={isActiveEdit}
                                            >
                                                <MenuItem value={1}>Active</MenuItem>
                                                <MenuItem value={2}>Inactive</MenuItem>
                                        </TextField>
                                            
                                        <div style={{textAlign: 'end', width: '100%',marginTop: '20px' }}>
                                            <Button variant='contained' onClick={() => saveEditedTemplate()} color='primary'>Save Changes</Button>
                                        </div>
                                    </div> 
                            </div>
                          
                        </div>                
                    </Modal>
                : null 
            }
            <div>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs example">
                <Tab label="System Templates" />
                <Tab label="Custom Templates" />
                </Tabs>
                {/* Content for each tab can be added here */}
                {tabValue === 0 && 
                <div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ margin: '15px 0 15px 10px' }}>{`${numOfRecords} results found`}</p>
                        <Button onClick={() => setShowStaticModal(true)} variant="contained">Create New Template</Button>
                    </div>
                                <div>                
                                    <table style={{ marginTop: '0' }} className={`${styles.table} `} >
                                        <thead>                  
                                            <tr>
                                                {
                                                    columns.map(col => {
                                                        return <th>{col.Header}</th>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.map((cell, index) => {
                                                    return <tr className={styles.tableRow}>                            
                                                        <td className={styles.tableData}>{cell.ref}</td>                      
                                                        <td className={styles.tableData}>{cell.subject}</td>                      
                                                        <td className={styles.tableData} dangerouslySetInnerHTML={{ __html: cell.email_msg }} />       
                                                        <td className={styles.tableData}>{cell.sms_msg}</td>                      
                                                        <td className={styles.tableData}>{cell.type}</td>                      
                                                        <td className={styles.tableData}>{cell.main_entity}</td>
                                                        <td className={styles.tableData}>{(cell.isActive)?      
                                                                <Chip
                                                                    size="small"
                                                                    customBackgroundColor={"#c0f3bd"}
                                                                    customBorder={"#EFF3FA"}
                                                                    customBorderRadius={"16px"}
                                                                    fontColor={"#192252"}
                                                                    label={"True"}
                                                                /> 
                                                        :      <Chip
                                                                    size="small"
                                                                    customBackgroundColor={"#f3bdbd"}
                                                                    customBorder={"#EFF3FA"}
                                                                    customBorderRadius={"16px"}
                                                                    fontColor={"#192252"}
                                                                    label={"False"}
                                                                />
                                                    }</td>
                                                        <td className={styles.tableData}>
                                                            <Button variant="contained" onClick={() => editTemplate(cell.id, index)}>Edit</Button>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                </div>
                }
                {tabValue === 1 && <div><p>Custom Templates</p></div>}
            </div>
        </div>
    );
}

export default MessagingTemplates;