
import { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Tooltip, Typography, Button, Chip, TextField, MenuItem, Box,
  Tabs,
  Tab,
  Modal,
  Select,
  Pagination,
  DialogActions,
  DialogTitle,
  Dialog,
  Divider,
  FormControlLabel,
  Checkbox,
  Badge
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Swal from 'sweetalert2';
import axios from '../../../apis/axios';
import {  AccessTime, AllInbox, Circle, DoneAll, Error, History, Home, OpenInNew, PhoneIphone, Refresh, Work } from '@mui/icons-material';
import { CloseButton } from '../../../components';
import useAuth from '../../../hooks/useAuth';
import moment from 'moment';
import { toast } from 'react-toastify';
import { green, yellow, red, blue, grey } from '@mui/material/colors';
import LogHistory from './LogHistory';
import { removeUnnecessaryWhitespace } from '../../../utils/HtmlValidation';

const CallLog = (props:{
  triggerNotification?: boolean;
  resetNotificationTrigger: () => void;
}) => {

  const { auth } = useAuth() as any
  const [rows, setRows] = useState([]) as any;
  const [filteredRows, setFilteredRows] = useState([]) as any;
  const [page, setPage] = useState(1) as any;
  const [pageSize, setPageSize] = useState(10) as any;
  const [totalRows, setTotalRows] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState('Completed');
  const [notes, setNotes] = useState('');
  const [openLogHistory, setOpenLogHistory] = useState(false);

  const [notifyByEmail, setNotifyByEmail] = useState(false);
  const [notifyBySMS, setNotifyBySMS] = useState(false);

  const [rowData, setRowData] = useState(null) as any;
  const [filters, setFilters] = useState({
    name: '',
    communicationType: 'All', 
    status: 'ALL' 
  });

  useEffect(() => {
    fetchCallLog();
    fetchCount()
  }, [page, pageSize, filters.status]); 

  const fetchCallLog = async () => {
    const apiUrl = new URL(`${process.env.REACT_APP_API_URL}CallCenter/GetCallCenterPendingLog`);
  
    apiUrl.searchParams.append('pageNumber', page);
    apiUrl.searchParams.append('pageSize', pageSize);
  
    if (filters.status === 'ALL') {
    } else {
      if (filters.status) {
        apiUrl.searchParams.append('status', filters.status);
      }
      if (['O', 'C', 'F'].includes(filters.status)) {
        apiUrl.searchParams.append('employeeID', auth?.employee?.numEmployeeID);
      }
    }
  
    try {
      const response = await axios.get(apiUrl.toString());
      setRows(response.data.data);
      console.log("Fetched Data:", response.data.data);
      setTotalRows(response.data.totalRecords); 
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  

  useEffect(() => {
    const newFilteredRows = (rows || []).filter(row => {
      const matchesName = row.SenderName.toLowerCase().includes(filters.name.toLowerCase());
      const matchesCommunicationType =
        filters.communicationType === 'All' || row.CommunicationMethodCode === filters.communicationType;
  
      return matchesName && matchesCommunicationType;
    });
  
    setFilteredRows(newFilteredRows);
  }, [filters.name, filters.communicationType, rows]);
  

  const handleFilterChange = (event) => {
    console.log("fillE", event)
    const { name, value } = event.target;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));

    setPage(1);
  };
  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };


  const handleCallCustomer = (id, index) => {
    Swal.fire({
      title: `Call ${id.SenderName}?`,
      html: `<strong>Contact Number:</strong> <span style="color: #d9534f; font-weight: bold;">${id.ContactNumber}</span><br><br>By clicking "Yes," you will reserve this job to yourself.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Call',
      cancelButtonText: 'Cancel',
      scrollbarPadding: false,
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(`tel:${id.ContactNumber}`, '_self');
        let payload = [
          {
            "jobID": id?.JobID,
            "messageID": id?.MessageID,
            "employeeID": auth?.employee?.numEmployeeID,
            "communicationMethod": id?.CommunicationMethodCode,
            "outcome": "Ongoing",
            "notes": "",
            "createdDateTime": id?.CreatedDateTime,
            "updatedDateTime": moment().toISOString(),
            "callStartTime": moment().toISOString(),
            "callEndTime": moment().toISOString(),
            "failureReason": null,
            "nextCommunicationMethod": id?.NextCommunicationMethod
          }
        ]
            console.log("payload- call occupy - sent", payload)

            populateCallAgentLog(payload)
            setButtonClicked(true);
      }
    });
  };
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedLog, setSelectedLog] = useState({}) as any;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleTabChange = (event, newIndex) => setTabIndex(newIndex);

  const handleView = (row)=> {
    setSelectedLog(row)
    console.log(row)
    setStatus("")
    setNotes("")
    handleOpen()
  }

  const getCustomerInfo = async (cusID) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}Customer/GetByID/${cusID}`

    try {
        const response = await axios.get(apiUrl, {})
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

    const handleOpenModal = (row) => {
      setRowData(row);
      setOpenModal(true);
    };
    const handleSaveChanges = () => {
      let payload = [
        {
          "jobID": rowData?.JobID,
          "messageID": rowData?.MessageID,
          "employeeID": auth?.employee?.numEmployeeID,
          "communicationMethod": rowData?.CommunicationMethodCode,
          "outcome": status,
          "notes": "",
          "createdDateTime": rowData?.CreatedDateTime,
          "updatedDateTime": moment().toISOString(),
          "callStartTime": moment().toISOString(),
          "callEndTime": moment().toISOString(),
          "failureReason": notes,
          "nextCommunicationMethod": rowData?.NextCommunicationMethod
        }
      ]
      console.log("payload sent", payload, rowData)
      populateCallAgentLog(payload)

        getCustomerInfo(rowData?.RecepientID)
        .then((appData) => {
          let customerData = appData?.customer
          if(notifyByEmail){
            let payloadMsgEmail = {
              messageBody: [
                  {
                      messageID: 1,
                      recipientID: customerData?.numCustomerID,
                      recipientType: "C",
                      senderID: auth?.employee?.numEmployeeID,
                      senderLocationID: auth?.currentLocation?.value,
                      subject: rowData?.Subject,
                      smsBody: rowData?.SMSBody,
                      emailBody: removeUnnecessaryWhitespace(rowData?.EmailBody),
                      communicationMethod: "E",
                      sender: customerData?.varCustomerFirstName + " " + customerData?.varCustomerLastName,
                      senderLocation: customerData?.varCustomerDefaultLocation,
                      contactNumber: customerData?.varCustomerMobilePhone,
                      emailAddress: customerData?.varCustomerEmail,
                      appointmentID: null,
                      option: customerData?.varCustomerEmail === null ? 1 : 2,
                      sendDate: new Date().toISOString().split("T")[0],
                      messageType: "I",
                      workPhoneNumber: customerData?.varCustomerWorkPhone,
                      homePhoneNumber: customerData?.varCustomerHomePhone,
                      bitCustomerNotificationEmail: true,
                      numCustomerNotificationEmailSequence: 1,
                      bitCustomerNotificationMobilePhoneSMS: false,
                      numCustomerNotificationMobilePhoneSMSSequence: 0,
                      bitCustomerNotificationHomePhone: false,
                      numCustomerNotificationHomePhoneSequence: 0,
                      bitCustomerNotificationMobilePhoneCall: false,
                      numCustomerNotificationMobilePhoneCallSequence: 0,
                      bitCustomerNotificationWorkPhone: false,
                      numCustomerNotificationWorkPhoneSequence: 0,
                      messageTemplateID: null,
                      attachmentModel: []
                  }
              ]
            }
            console.log("Email", payloadMsgEmail)
            sendMessagePost(payloadMsgEmail)
          }
          if (notifyBySMS){
            let payloadMsgSMS = {
              messageBody: [
                  {
                      messageID: 1,
                      recipientID: customerData?.numCustomerID,
                      recipientType: "C",
                      senderID: auth?.employee?.numEmployeeID,
                      senderLocationID: auth?.currentLocation?.value,
                      subject: rowData?.Subject,
                      smsBody: rowData?.SMSBody,
                      emailBody: removeUnnecessaryWhitespace(rowData?.EmailBody),
                      communicationMethod: "S",
                      sender: customerData?.varCustomerFirstName + " " + customerData?.varCustomerLastName,
                      senderLocation: customerData?.varCustomerDefaultLocation,
                      contactNumber: customerData?.varCustomerMobilePhone,
                      emailAddress: customerData?.varCustomerEmail,
                      appointmentID: null,
                      option: customerData?.varCustomerEmail === null ? 1 : 2,
                      sendDate: new Date().toISOString().split("T")[0],
                      messageType: "I",
                      workPhoneNumber: customerData?.varCustomerWorkPhone,
                      homePhoneNumber: customerData?.varCustomerHomePhone,
                      bitCustomerNotificationEmail: false,
                      numCustomerNotificationEmailSequence: 0,
                      bitCustomerNotificationMobilePhoneSMS: true,
                      numCustomerNotificationMobilePhoneSMSSequence: 1,
                      bitCustomerNotificationHomePhone: false,
                      numCustomerNotificationHomePhoneSequence: 0,
                      bitCustomerNotificationMobilePhoneCall: false,
                      numCustomerNotificationMobilePhoneCallSequence: 0,
                      bitCustomerNotificationWorkPhone: false,
                      numCustomerNotificationWorkPhoneSequence: 0,
                      messageTemplateID: null,
                      attachmentModel: []
                  }
              ]
            }
            console.log("SMS", payloadMsgSMS)
            sendMessagePost(payloadMsgSMS)
          }
        })
        .catch((error) => {
            console.log("error", error)
        })
    };

    async function sendMessagePost(data) {
      await axios.post(`${process.env.REACT_APP_TEXT_API_URL}UpdateMessages/UpdateMessagesPool`, 
      JSON.stringify(data),
      {
          headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              
          },
      }).then((result) => {
          if (result.status === 200) {
  
              console.log("Message sent to pool")
          }
      }).catch((err) => {
  
          console.log("Messaging -Err-", err);
      })
  }

    async function populateCallAgentLog(data) {
      await axios
          .post(`${process.env.REACT_APP_API_URL}CallCenter/PopulateCallAgentJobs`, JSON.stringify(data), {
              headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
              },
          })
          .then((result) => {
              console.log("save-res", result)
              if (result.status === 200) {
                  toast.success("Changes successfully saved!")
                  fetchCallLog()
                  fetchCount()
                  setOpenModal(false);
              }
          })
          .catch((err) => {
              console.log(err)
              toast.error("Something went wrong!")
          })
  }
  const handleCloseModal = () => {
    setOpenModal(false);
  };


  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
      if (props.triggerNotification) {
          setButtonClicked(false); 
          toast.info(
            "New changes incoming! Please refresh to update the table.",
            {
              autoClose: false,  
              closeButton: true,  
              draggable: false,  
            }
          );
          fetchCount()
      }
  }, [props.triggerNotification]);

  const refreshData = () => {
      fetchCallLog();
      fetchCount()
      setButtonClicked(true); 
      props.resetNotificationTrigger();
  };

  function getStatusChip(status) {
    let chipColor;
    let label = status;
  
    switch (status) {
      case 'Completed':
        chipColor = green[500];
        break;
      case 'Pending':
        chipColor = yellow[700];
        break;
      case 'Failed':
        chipColor = red[500];
        break;
      case 'Ongoing':
        chipColor = blue[500];
        break;
      default:
        chipColor = grey[500];
        label = 'Default'; 
    }
  
    return (
      <Chip
        label={label}
        style={{
          backgroundColor: chipColor,
          color: 'white',
        }}
      />
    );
  }

  const statusReasons = {
    Failed: [
      "Did not pick up the call",
      "Call didn’t go through",
      "Incorrect phone number",
    ],
    Completed: [
      "Task successfully completed",
      "Follow-up not needed",
      "Customer confirmed resolution",
    ],
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setNotes(""); 
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleReasonClick = (reason) => {
    setNotes((prevNotes) => {
      const notesArray = prevNotes.split("\n").filter((line) => line.trim() !== "");

      if (notesArray.includes(reason)) {
        return notesArray.filter((line) => line !== reason).join("\n");
      } else {
        return [...notesArray, reason].join("\n");
      }
    });
  };

  const [selectedStatus, setSelectedStatus] = useState("ALL");

  const handleStatusChangeMain = (statusKey) => {
    setSelectedStatus(statusKey);
    handleFilterChange({ target: { name: "status", value: statusKey === "ALL" ? "" : statusKey } });
  };


  const getStatusCount = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}CallCenter/GetCallCenterJobCounts?numEmployeeID=${auth?.employee?.numEmployeeID}`
  
    try {
        const response = await axios.get(apiUrl, {})
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
  }

  const [statusCounts, setStatusCounts] = useState({}) as any;

  const fetchCount = () => {
    getStatusCount()
      .then((DynamicData) => {
        console.log("entity data", DynamicData);

        setStatusCounts({
          P: DynamicData.find((item) => item.Status === "Pending")?.Count || 0,
          C: DynamicData.find((item) => item.Status === "MyCompleted")?.Count || 0,
          F: DynamicData.find((item) => item.Status === "MyFailed")?.Count || 0,
          O: DynamicData.find((item) => item.Status === "MyJobs")?.Count || 0,
          ALL: DynamicData.find((item) => item.Status === "ALL")?.Count || 0,
        })
  
        console.log("Mapped Status Counts:", statusCounts);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };


  

  const statuses = [
    { key: "P", label: "Pending", color: "primary", icon: <AccessTime /> },
    { key: "O", label: "My Jobs", color: "info", icon: <Work /> },
    { key: "C", label: "Completed", color: "success", icon: <DoneAll /> },
    { key: "F", label: "Failed", color: "error", icon: <Error /> },
    { key: "ALL", label: "All", color: "default", icon: <AllInbox /> },
  ];

  useEffect(()=>{
    setNotes("")
    setStatus("Completed")
  },[openModal])
  


  const StatusWidget = ({ status, count, selected, onClick }) => (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "12px",
        borderRadius: "8px",
        cursor: "pointer",
        gap: "8px",
        border: selected
          ? `2px solid ${status.color === "default" ? "#bdbdbd" : `theme.palette.${status.color}.main`}`
          : "1px solid #e0e0e0",
        backgroundColor: selected ? "#f0f0f0" : "#fff",
        boxShadow: selected ? "0px 0px 8px rgba(0, 0, 0, 0.1)" : "none",
        transition: "0.3s ease-in-out",
        "&:hover": {
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
        },
        position: "relative", 
      }}
    >
      <Badge
        badgeContent={count || 0}
        color={status.color}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          animation: status.label === "Pending" && count > 0 ? "pulse 1s infinite" : "none", 
          "@keyframes pulse": {
            "0%": { transform: "scale(1)", opacity: 1 },
            "50%": { transform: "scale(1.05)", opacity: 0.8 }, 
            "100%": { transform: "scale(1)", opacity: 1 },
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {status.icon}
          <Typography variant="body1" fontWeight={selected ? "bold" : "normal"} sx={{ marginRight: "12px" }}>
            {status.label}
          </Typography>
        </Box>
      </Badge>
    </Box>
  );
  
  const [sortDirection, setSortDirection] = useState("asc"); 

const handleSortByDate = () => {
  const newDirection = sortDirection === "asc" ? "desc" : "asc";
  setSortDirection(newDirection);

  filteredRows.sort((a, b) => {
    const dateA:any = new Date(a.CreatedDateTime);
    const dateB:any = new Date(b.CreatedDateTime);
    return newDirection === "asc" ? dateA - dateB : dateB - dateA;
  });
};
  
  
  return (
    <Box sx={{ width: '70vw', margin: '0 auto' }}>
      <TableContainer component={Paper}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding:"10px" }}>
        <Typography variant="h5" gutterBottom sx={{ padding: '16px' }}>
          Call Log
        </Typography>
        <div style={{display:"flex", gap:"10px"}}>
          <Button
              variant="contained"
              color="success"
              startIcon={<History />}
              onClick={()=>{setOpenLogHistory(true)}}
              sx={{ height: 'auto', padding: '8px 16px' }}
          >
              Log History
          </Button>
          {props.triggerNotification && !buttonClicked && (
                  <Button
                      variant="contained"
                      color="info"
                      startIcon={<Refresh />}
                      onClick={refreshData}
                      sx={{ height: 'auto', padding: '8px 16px' }}
                  >
                      Refresh
                  </Button>
              )}
          </div>
    </div>

    <LogHistory isOpenLogHistoryModal={openLogHistory}  setIsOpenLogHistory={(value) => setOpenLogHistory(value)}/> 


    <div>
      <div style={{ display: "flex", gap: "16px", padding: "16px" }}>
        <TextField
          label="Customer Name"
          variant="outlined"
          name="name"
          value={filters.name}
          onChange={handleFilterChange}
          size="small"
          sx={{ flexGrow: 1 }}
        />
        <TextField
          label="Communication Type"
          variant="outlined"
          name="communicationType"
          value={filters.communicationType}
          onChange={handleFilterChange}
          select
          size="small"
          sx={{ width: "150px" }}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="C">Mobile</MenuItem>
          <MenuItem value="H">Home</MenuItem>
          <MenuItem value="W">Work</MenuItem>
        </TextField>
      </div>

    <Box sx={{ display: "flex", gap: "16px", padding: "16px", position: "relative" }}>
      {statuses.map((status) => (
        <StatusWidget
          key={status.key}
          status={status}
          count={statusCounts[status.key]}
          selected={selectedStatus === status.key}
          onClick={() => handleStatusChangeMain(status.key)}
        />
      ))}
      {/* Live Indicator */}
      <Chip
        icon={<Circle sx={{ animation: "pulse 1.5s infinite", fontSize: "12px" }} />}
        label="Live Updates"
        color="success"
        variant="outlined"
        sx={{
          position: "absolute",
          top: "-8px",
          right: "16px",
          "@keyframes pulse": {
            "0%": { transform: "scale(1)", opacity: 1 },
            "50%": { transform: "scale(1.2)", opacity: 0.6 },
            "100%": { transform: "scale(1)", opacity: 1 },
          },
        }}
      />
    </Box>

      <TableContainer>
        <Table aria-label="customer notification table">
        <TableHead>
          <TableRow>
            <TableCell onClick={handleSortByDate} style={{ cursor: "pointer" }}>
              Date {sortDirection === "asc" ? "↑" : "↓"}
            </TableCell>
            <TableCell>Message Title</TableCell>
            <TableCell>Customer Name</TableCell>
            <TableCell>Contact Number</TableCell>
            <TableCell>Comm. Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>View</TableCell>
            <TableCell>Call</TableCell>
            <TableCell>Mark as Done</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRows.map((row, index) => (
            <TableRow key={index} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}>
              <TableCell>{moment(row.CreatedDateTime).format("MMMM Do YYYY, h:mm:ss a")}</TableCell>
              <TableCell>{row.Subject}</TableCell>
              <TableCell>{row.SenderName}</TableCell>
              <TableCell>{row.ContactNumber}</TableCell>
              <TableCell>
                {row.CommunicationMethodCode === "C" ? (
                  <Tooltip title={<p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Mobile Phone</p>}>
                    <PhoneIphone color="success" />
                  </Tooltip>
                ) : row.CommunicationMethodCode === "H" ? (
                  <Tooltip title={<p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Home Phone</p>}>
                    <Home color="secondary" />
                  </Tooltip>
                ) : (
                  <Tooltip title={<p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Work Phone</p>}>
                    <Work color="warning" />
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>
                <Tooltip title="Status">{getStatusChip(row.Outcome)}</Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="More info">
                  <IconButton onClick={() => handleView(row)}>
                    <OpenInNew color="primary" />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="Call Customer">
                  <IconButton onClick={() => handleCallCustomer(row, index)} disabled={row?.Outcome !== "Pending"}>
                    <PhoneIcon color={row?.Outcome === "Pending" ? "primary" : "disabled"} />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<AssignmentTurnedInIcon />}
                  onClick={() => handleOpenModal(row)}
                  disabled={!(row?.Outcome === "Pending" || row?.Outcome === "Ongoing")}
                >
                  Done
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Table>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px" }}>
          <Select value={pageSize} onChange={handlePageSizeChange}>
            {[5, 10, 20, 50].map((size) => (
              <MenuItem key={size} value={size}>
                {size} per page
              </MenuItem>
            ))}
          </Select>

          <Pagination
            count={Math.ceil(totalRows / pageSize)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            boundaryCount={2}
            showFirstButton
            showLastButton
          />
        </div>
      </TableContainer>
    </div>
      </TableContainer>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            width: 500,
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <div style={{textAlign:"end"}}>
          <CloseButton   onClick={handleClose} />
          </div>

          <Tabs value={tabIndex} onChange={handleTabChange} centered>
            <Tab label="Email" />
            <Tab label="SMS" />
          </Tabs>

          {tabIndex === 0 && (
            <Typography
              component="div"
              dangerouslySetInnerHTML={{ __html: selectedLog?.EmailBody }}
              sx={{ mt: 2 }}
            />
          )}
          {tabIndex === 1 && (
            <Typography
              component="div"
              dangerouslySetInnerHTML={{ __html: selectedLog?.SMSBody }}
              sx={{ mt: 2 }}
            />
          )}
        </Box>
      </Modal>

      <Dialog open={openModal} onClose={handleCloseModal}>
      <DialogTitle>Mark Task as Done</DialogTitle>
      <div style={{ width: "400px", padding: "20px" }}>
        <Select
          value={status}
          onChange={handleStatusChange}
          fullWidth
          sx={{ marginBottom: "16px" }}
        >
          <MenuItem value="Completed">Completed</MenuItem>
          <MenuItem value="Failed">Failed</MenuItem>
        </Select>

        {status && (
          <div style={{ marginBottom: "16px" }}>
            <strong>Select Reason:</strong>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {statusReasons[status].map((reason, index) => (
                <li
                  key={index}
                  onClick={() => handleReasonClick(reason)}
                  style={{
                    cursor: "pointer",
                    padding: "8px",
                    background: "#f5f5f5",
                    margin: "4px 0",
                    borderRadius: "4px",
                  }}
                >
                  {reason}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Textarea for Notes */}
        <TextField
          label="Notes (Optional)"
          multiline
          rows={4}
          value={notes}
          onChange={handleNotesChange}
          fullWidth
        />
      </div>

      <Divider />

      {/* Notify Customer Section */}
      <div style={{ marginBottom: "10px", padding:"10px" }}>
        <strong>Notify Customer:</strong>
        <Box display="flex" flexDirection="row" gap="10px" marginTop="8px">
          <FormControlLabel
            control={
              <Checkbox
                checked={notifyByEmail}
                onChange={(e) => setNotifyByEmail(e.target.checked)}
                disabled={(status === "Failed")? true : false}
              />
            }
            label="Email"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={notifyBySMS}
                onChange={(e) => setNotifyBySMS(e.target.checked)}
                disabled={(status === "Failed")? true : false}
              />
            }
            label="SMS"
          />
        </Box>
      </div>


      {/* Save Button */}
      <DialogActions>
        <Button onClick={handleCloseModal} color="secondary">
          Cancel
        </Button>
        <Button  onClick={handleSaveChanges} color="primary" variant="contained">
          {notifyByEmail || notifyBySMS ? "Save and Notify Customer" : "Save Changes"}
        </Button>
      </DialogActions>
    </Dialog>
    </Box>
  );
};

export default CallLog;

