
import { MenuItem, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Avatar, Button, CloseButton, Chip, Input as OwnInput } from '..';
import { Modal } from '../components';
import axios, { axiosPrivate } from "../../apis/axios";
import { toast } from "react-toastify";
import { fullDate } from '../../utils/DateFormatter';
import Summery from './Summery';
import Steps from '../../TabletView/pages/Components/Steps/Steps';
import placeholderImage from "../../images/pet_placeholder.png"
import InputMask from "react-input-mask"
import AdvancedDropDown from '../AdvancedDropDown/AdvancedDropDown';
import { sendAppointmentStaticMessage, sendOutNotification } from '../../utils/Messaging';
import Swal from "sweetalert2";

const CheckOutModal = (props: {
    bookingDetails;
    isOpenCheckOutModal;
    setIsOpenCheckOutModal: CallableFunction;
    isSuccess?
    remainTotal?
    setIsOpenPaymentModal: CallableFunction;
}) => {

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [isOpenWellnessCheckModal, setIsOpenWellnessCheckModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenEditDetails, setIsOpenEditDetails] = useState(false);
    const [knowAboutUsList, setKnowAboutUsList] = useState<any>([]);

    const [showFieldsDropOff, setShowFieldsDropOff] = useState(false)
    const [showFieldsRelatedDropOff, setShowFieldsRelatedDropOff] = useState(false)


    //console.log(props.bookingDetails)

    const [showFieldsPickup, setShowFieldsPickup] = useState(0)
    const [showFieldsRelatedPickup, setShowFieldsRelatedPickup] = useState(false)

    const [checkInDetails, setCheckInDetails] = useState<any>([])
    const [relationshipList, setRelationshipList] = useState<any>([])
    const [linkedCustomers, setLinkedCustomers] = useState<any>([])
    const [linkedCustomerContact, setLinkedCustomerContact] = useState<any>({})
    const relationshipDropOffRef = useRef<any>()
    const linkedPickupRef = useRef<any>()
    



    async function getCheckinDetails(bookingID) {

        await axios
            .get(`${process.env.REACT_APP_API_URL}CheckIn/getCheckInByAppointmentID?AppointmentID=${bookingID}`)
            .then((result) => {
                if (result.status === 200) {
                    console.log("checkIn", result.data.CheckIn)
                    setCheckInDetails(result.data.CheckIn)
                    if (result.data.CheckIn?.PickUpPersonType === "Third Party") {
                        setShowFieldsPickup(3)
                    } else if (result.data.CheckIn?.PickUpPersonType === "Linked Customer") {
                        setShowFieldsPickup(2)
                    } else {
                        setShowFieldsPickup(1)
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function ContactPersonGetAll() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`ContactPerson/GetAll`)
        return result
    }

    async function getLinkedUsersByCustomerID(id) {
        setIsLoading(true)
        const result: any = await axiosPrivate.get(`Customer/GetLinkedAccountsByID/${id}`)
        return result
    }

    const setPickupTypeChanged = (e) => {
        if (e.target.value === "Third Party") {
            setShowFieldsPickup(3)
            const checkInData = { ...checkInDetails } as any
            checkInData.PickUpPersonType = "Third Party"
            setCheckInDetails(checkInData)
        } else if (e.target.value === "Linked Customer") {
            setShowFieldsPickup(2)
            const checkInData = { ...checkInDetails } as any
            checkInData.PickUpPersonType = "Linked Customer"
            setCheckInDetails(checkInData)
        } else {
            setShowFieldsPickup(1)
            const checkInData = { ...checkInDetails } as any
            checkInData.PickUpPersonType = "Customer"
            setCheckInDetails(checkInData)
        }
    }

    useEffect(() => {
        if (props.isOpenCheckOutModal === true) {

            ContactPersonGetAll()
                .then((res) => {
                    setRelationshipList(res.data.contactPerson)
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    toast.error("Something went wrong!")
                })
            getLinkedUsersByCustomerID(props.bookingDetails.Customer.numCustomerID)
                .then((response) => {
                    setLinkedCustomers(response.data.customer)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                    setIsLoading(false)
                })
                getCheckinDetails(props.bookingDetails?.numAppointmentID)
        }
    }, [props.isOpenCheckOutModal])

    const handleCheckOut = () => {

        if(parseFloat(props.remainTotal) > 0){
            Swal.fire({
                title: `Caution!`,
                text: `There's a pending balance of $${props.remainTotal} Are you sure you want to check out?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Checkout',
                cancelButtonText: 'No, Make payment'
              }).then((result) => {
                if (result.isConfirmed) {
    
                    let checkedKnowAboutUs: any = []
                    checkInDetails?.HowDoYouKnowAboutUs
                        .filter((x) => x.IsAppKnowAboutUsActive === true)
                        .map((tab, index) => {
                            checkedKnowAboutUs.push(  {
                                "numCheckInID": checkInDetails.CheckInID,
                                "numKnowAboutUsID": tab?.numKnowAboutUsID,
                                "bitActive": true
                              })
                        })
            
                    let payload = {
                        checkInID: checkInDetails.CheckInID,
                        appointmentID: checkInDetails?.Appointment?.numAppointmentID,
                        checkInStatus: checkInDetails?.CheckInStatus,
                        dropOffPersonType: checkInDetails?.DropOffPersonType,
                        dropOffPersonID:
                            checkInDetails?.DropOffPersonType === "Customer"
                                ? checkInDetails?.Customer?.numCustomerID
                                : checkInDetails?.DropOffPersonType === "Linked Customer"
                                ? checkInDetails?.DropOffPersonID
                                : checkInDetails?.DropOffPersonType === "Third Party"
                                ? null
                                : null,
                        dropOffThirdPartyName: checkInDetails?.DropOffPersonType === "Third Party" ? checkInDetails?.DropOffThirdPartyName : null,
                        dropOffThirdPartyRelationshipID: checkInDetails?.DropOffPersonType === "Third Party" ? checkInDetails?.DropOffThirdPartyRelationshipID : null,
                        dropOffThirdPartyContactNo: checkInDetails?.DropOffPersonType === "Third Party" ? checkInDetails?.DropOffThirdPartyContactNo : null,
                        pickUpPersonType: checkInDetails?.PickUpPersonType,
                        pickUpPersonID:
                            checkInDetails?.PickUpPersonType === "Customer"
                                ? checkInDetails?.Customer?.numCustomerID
                                : checkInDetails?.PickUpPersonType === "Linked Customer"
                                ? checkInDetails?.PickUpPersonID
                                : checkInDetails?.PickUpPersonType === "Third Party"
                                ? null
                                : null,
                        pickUpThirdPartyName: checkInDetails?.PickUpPersonType === "Third Party" ? checkInDetails?.PickUpThirdPartyName : null,
                        pickUpThirdPartyRelationshipID: checkInDetails?.PickUpPersonType === "Third Party" ? checkInDetails?.PickUpThirdPartyRelationshipID : null,
                        pickUpThirdPartyContactNo: checkInDetails?.PickUpPersonType === "Third Party" ? checkInDetails?.PickUpThirdPartyContactNo : null,
                        isPetHasAllergies: checkInDetails?.IsPetHasAllergies,
                        isFragrance: checkInDetails?.IsFragrance,
                        isAdditionalCharges: checkInDetails?.IsPetHasAllergies,
                        cageID: props.bookingDetails.CageID,
                        notes: checkInDetails?.Notes,
                        numEditedBy: 1,
                        numLocationID: 1,
                        knowAboutUsList: checkedKnowAboutUs,
                    }
                    console.log("put-checkin", payload)
                    putCheckIn(payload)

                } else if (result.dismiss === Swal.DismissReason.cancel) {
                        //Trigger payment modal
                        props?.setIsOpenPaymentModal(true)
                }
              });
        }else {
            let checkedKnowAboutUs: any = []
            checkInDetails?.HowDoYouKnowAboutUs
                .filter((x) => x.IsAppKnowAboutUsActive === true)
                .map((tab, index) => {
                    checkedKnowAboutUs.push(  {
                        "numCheckInID": checkInDetails.CheckInID,
                        "numKnowAboutUsID": tab?.numKnowAboutUsID,
                        "bitActive": true
                      })
                })
    
            let payload = {
                checkInID: checkInDetails.CheckInID,
                appointmentID: checkInDetails?.Appointment?.numAppointmentID,
                checkInStatus: checkInDetails?.CheckInStatus,
                dropOffPersonType: checkInDetails?.DropOffPersonType,
                dropOffPersonID:
                    checkInDetails?.DropOffPersonType === "Customer"
                        ? checkInDetails?.Customer?.numCustomerID
                        : checkInDetails?.DropOffPersonType === "Linked Customer"
                        ? checkInDetails?.DropOffPersonID
                        : checkInDetails?.DropOffPersonType === "Third Party"
                        ? null
                        : null,
                dropOffThirdPartyName: checkInDetails?.DropOffPersonType === "Third Party" ? checkInDetails?.DropOffThirdPartyName : null,
                dropOffThirdPartyRelationshipID: checkInDetails?.DropOffPersonType === "Third Party" ? checkInDetails?.DropOffThirdPartyRelationshipID : null,
                dropOffThirdPartyContactNo: checkInDetails?.DropOffPersonType === "Third Party" ? checkInDetails?.DropOffThirdPartyContactNo : null,
                pickUpPersonType: checkInDetails?.PickUpPersonType,
                pickUpPersonID:
                    checkInDetails?.PickUpPersonType === "Customer"
                        ? checkInDetails?.Customer?.numCustomerID
                        : checkInDetails?.PickUpPersonType === "Linked Customer"
                        ? checkInDetails?.PickUpPersonID
                        : checkInDetails?.PickUpPersonType === "Third Party"
                        ? null
                        : null,
                pickUpThirdPartyName: checkInDetails?.PickUpPersonType === "Third Party" ? checkInDetails?.PickUpThirdPartyName : null,
                pickUpThirdPartyRelationshipID: checkInDetails?.PickUpPersonType === "Third Party" ? checkInDetails?.PickUpThirdPartyRelationshipID : null,
                pickUpThirdPartyContactNo: checkInDetails?.PickUpPersonType === "Third Party" ? checkInDetails?.PickUpThirdPartyContactNo : null,
                isPetHasAllergies: checkInDetails?.IsPetHasAllergies,
                isFragrance: checkInDetails?.IsFragrance,
                isAdditionalCharges: checkInDetails?.IsPetHasAllergies,
                cageID: props.bookingDetails.CageID,
                notes: checkInDetails?.Notes,
                numEditedBy: 1,
                numLocationID: 1,
                knowAboutUsList: checkedKnowAboutUs,
            }
            console.log("put-checkin", payload)
            putCheckIn(payload)
        }


       
    }

    async function putCheckIn(data) {
        await axios
            .put(`${process.env.REACT_APP_API_URL}CheckIn/UpdateCheckIn`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log(`Check-In details updated!`)
                    let code = "CO"
                    sendAppointmentStaticMessage(checkInDetails?.Appointment?.numAppointmentID, "Appointment", 1002, "E", [], code)
                    let notificationPostBody ={
                        "notificationType": "success",
                        "subject": "Confirmation of Checked-Out Ref#:"+props.bookingDetails.numAppointmentID,
                        "notificationMessage": `We're delighted to inform you that your pet ${props.bookingDetails?.Pet?.PetName} grooming appointment has been successfully completed and now checked out from our ${props.bookingDetails?.varLocationName} facility`,
                        "locationID": 1,
                        "locationName": props.bookingDetails?.varLocationName,
                        "recipientType": "C",
                        "recipientID": props.bookingDetails?.Customer?.numCustomerID,
                        "recipientName": props.bookingDetails?.Customer?.varCustomerFirstName +" "+props.bookingDetails?.Customer?.varCustomerLastName,
                        "isSeen": false,
                        isPushed: false
                    }
                    sendOutNotification(notificationPostBody)
                    completeCheckOut()
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function updateStatus(data) {
        await axios.put(`${process.env.REACT_APP_API_URL}Appointment/UpdateAppointmentStatus`,
        JSON.stringify(data),
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',                
            },
        }).then((result) => {
            if (result.status === 200) {
                console.log("APPOINTMENT STATUS - UPDATED : PAYMENT COMPLETE")
                let payLoad = {
                    numAppointmentID: props.bookingDetails?.numAppointmentID,
                    IsBooked: false
                }
                releaseCage(payLoad)
            }
        }).catch((err) => {
            console.log(err);
            toast.error('Something went wrong!');
        })
    }

    async function releaseCage(data) {
        console.log("cageData", data)
        await axios.put(`${process.env.REACT_APP_API_URL}CheckOut/UpdateCheckOutDetails?numAppointmentID=${data?.numAppointmentID}&IsBooked=${data?.IsBooked}`,
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',                
            },
        }).then((result) => {
            if (result.status === 200) {
                console.log("APPOINTMENT CAGE - RELEASED")
                getCheckinDetails(props.bookingDetails?.numAppointmentID)
                props.isSuccess(true)
                props.setIsOpenCheckOutModal(false)

            }
        }).catch((err) => {
            console.log(err);
            toast.error('Something went wrong!');
        })
    }


    const completeCheckOut = () => {
        let payload = {
            "numAppointmentID": props.bookingDetails?.numAppointmentID,
            "apptStatus": "CHECKED-OUT",
            "isApptCheckedInStatus": props.bookingDetails?.IsApptCheckedInStatus,
            "isServiceArea": props.bookingDetails?.IsServiceArea,
            "isReadyForPickUp": props.bookingDetails?.IsReadyForPickUp,
            "isPaymentMade": (parseFloat(props.remainTotal)> 0)? false : true,
            "isCheckedOut": true,
            "numEditedBy": 1
        }
        updateStatus(payload)
    }

    useEffect(()=>{
        if(showFieldsPickup === 2 && checkInDetails.PickUpPersonID !== null && checkInDetails.PickUpPersonID !== "" && checkInDetails.PickUpPersonID !== undefined && linkedCustomers.length > 0){
            const filteredCustomers = linkedCustomers.filter(customer => customer.numCustomerID === checkInDetails.PickUpPersonID);
            setLinkedCustomerContact({
                phone: filteredCustomers[0]?.varCustomerMobilePhone,
                work: filteredCustomers[0]?.varCustomerWorkPhone,
                home: filteredCustomers[0]?.varCustomerHomePhone
            })
        }

    },[showFieldsPickup, checkInDetails.PickUpPersonID, linkedCustomers])

    return (
        <div>
            <Modal open={props.isOpenCheckOutModal} backgroundColor={"#F9FAFB"} >
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '1rem', width: "100%" }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%", alignItems: "center" }}>
                        <p style={{ fontSize: "28px", fontWeight: "600", color: "#192252" }}>Check Out List</p>
                        <CloseButton onClick={() => props.setIsOpenCheckOutModal(false)} />
                    </div>

                    <div style={{ padding: "1rem", width: "100%" }}>
                        <div style={{ display: "flex", gap: "4rem", border: "1px solid #F3F4F6", padding: '10px 0', borderRadius: "8px", backgroundColor: "#FFFFFF", width: "100%" }}>
                            <div>
                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#606D93" }}>Booking ID #</p>
                                <p style={{ fontSize: "14px", fontWeight: "500", color: "#192252" }}>#{props?.bookingDetails?.numAppointmentID}</p>
                            </div>
                            <div>
                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#606D93" }}>Date/Time</p>
                                <p style={{ fontSize: "14px", fontWeight: "500", color: "#192252" }}>{fullDate(props?.bookingDetails?.dtApptStartTime)}</p>
                            </div>
                            <div>
                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#606D93" }}>Center</p>
                                <p style={{ fontSize: "14px", fontWeight: "500", color: "#192252" }}>{props?.bookingDetails?.varLocationName}</p>
                            </div>
                            <div style={{ borderRight: "1px solid #E5E7EB" }}></div>
                            <div>
                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#606D93" }}>Customer</p>
                                <p style={{ fontSize: "14px", fontWeight: "500", color: "#192252" }}>{props?.bookingDetails?.Customer?.varCustomerFirstName} {props?.bookingDetails?.Customer?.varCustomerLastName}</p>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: ".5rem", background:"white", width:"100%", padding:"10px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Avatar
                                    shape="rounded"
                                    img={
                                        props.bookingDetails.Pet?.PetImagePath == "" || props.bookingDetails.Pet?.PetImagePath == null
                                            ? placeholderImage
                                            : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + props.bookingDetails.Pet?.PetImagePath
                                    }
                                    isVip={false}
                                    className={"medium"}
                                />
                            </div>

                            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                <div style={{ fontWeight: 600, fontSize: "16px", color: "#002867" }}>{props.bookingDetails.Pet?.PetName}</div>
                                <div style={{ display: "flex", gap: "12px", alignItems: "center", marginTop: ".2rem" }}>
                                    <div style={{ fontWeight: 400, fontSize: 14, color: "#848FAC" }}>{props.bookingDetails.Pet?.PetBreed}</div>
                                </div>
                                <div style={{ display: "flex", marginTop: ".5rem", gap: ".3rem", alignItems: "center" }}>
                                    <Chip
                                        size="small"
                                        customBackgroundColor={"#EFF3FA"}
                                        customBorder={"#EFF3FA"}
                                        customBorderRadius={"16px"}
                                        fontColor={"#192252"}
                                        label={props.bookingDetails.Pet?.varPetSizeName}
                                    />
                                    <Chip
                                        size="small"
                                        customBackgroundColor={"#EFF3FA"}
                                        customBorder={"#EFF3FA"}
                                        customBorderRadius={"16px"}
                                        fontColor={"#192252"}
                                        label={props.bookingDetails.Pet?.PetSex === "M" ? "Male" : "Female"}
                                    />
                                    <Chip
                                        size="small"
                                        customBackgroundColor={"#EFF3FA"}
                                        customBorder={"#EFF3FA"}
                                        customBorderRadius={"16px"}
                                        fontColor={"#192252"}
                                        label={props.bookingDetails.Pet?.PetType}
                                    />
                                    <Chip
                                        size="small"
                                        customBackgroundColor={"#EFF3FA"}
                                        customBorder={"#EFF3FA"}
                                        customBorderRadius={"16px"}
                                        fontColor={"#192252"}
                                        label={props.bookingDetails.Pet?.PetColor}
                                    />
                                    {/* <Warning style={{ color: "#EB1A1A", fontSize: "20px" }} /> */}
                                </div>
                            </div>
                        </div>
                        <div>
             
                        </div>
                        </div>

                    <div style={{ height: "550px", overflowY: "scroll", width: "100%" }}>

                        <div style={{ backgroundColor: '#fff', padding: '15px' }}>
                            <p style={{ fontWeight: '600', fontSize: '14px', color:"#002867" }}>Service Status</p>
                            <div style={{padding:"10px"}}>
                                <Steps statusSummary={props.bookingDetails} color='#22C55E' generalType={true}/>
                            </div>
                        </div>

                        {/* <div style={{ backgroundColor: '#fff', padding: '15px', marginTop: '1rem' }}>
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <p style={{ fontWeight: '600', fontSize: '14px' }}>Products</p>
                                    <Button color="primary" variant="contained" text={"Add / Edit Products"} />
                                </div>
                            </div>
                        </div> */}
                        
                        <p style={{ fontWeight: '600', fontSize: '14px', color:"#002867", padding: '15px' }}>Billing Summary</p>
                        <div style={{padding:"10px"}}>
                            <Summery isPassedInvoice={false}  appDetails={{numAppointmentID: props.bookingDetails.numAppointmentID}} setRefresh={props.remainTotal}/> 
                        </div>
                        <div style={{ paddingTop: "10px", display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                onClick={() => props?.setIsOpenPaymentModal(true)}
                                disabled={parseFloat(props.remainTotal) > 0 ? false : true}
                                color={parseFloat(props.remainTotal) > 0 ? "primary" : "default"}
                                variant={parseFloat(props.remainTotal) > 0 ? "contained" : "outlined"}
                                text="Make Payment"
                            />
                        </div>
                    

                        <p style={{ fontWeight: '600', fontSize: '14px', color:"#002867", padding: '15px' }}>Pickup Person</p>
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "1rem", rowGap: "2ch", alignItems: "center", marginTop:"10px" }}>
                            <div>
                                {
                                    <TextField
                                        onChange={(e) => setPickupTypeChanged(e)}
                                        select={true}
                                        variant={"filled"}
                                        fullWidth
                                        label={
                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                Pickup person
                                            </div>
                                        }
                                        required={true}
                                        inputProps={{
                                            pattern: "^[a-zA-Z ]*$",
                                        }}
                                        value={checkInDetails.PickUpPersonType !== undefined ? checkInDetails.PickUpPersonType : ""}
                                    >
                                        <MenuItem value={"Customer"}>Customer</MenuItem>
                                        <MenuItem value={"Linked Customer"}>Linked Customer</MenuItem>
                                        <MenuItem value={"Third Party"}>Third Party</MenuItem>
                                    </TextField>
                                }
                            </div>

                            {showFieldsPickup === 3 ? (
                                <>
                                    <div>
                                        {
                                            <TextField
                                                inputProps={{
                                                    pattern: "^[a-zA-Z ]*$",
                                                }}
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        Name
                                                        {/* {(true) ?
                                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                        : null} */}
                                                    </div>
                                                }
                                                required={true}
                                                type={"text"}
                                                variant="filled"
                                                onChange={(e) => {
                                                    const checkInData = { ...checkInDetails }
                                                    checkInData.PickUpThirdPartyName = e.target.value
                                                    setCheckInDetails(checkInData)
                                                }}
                                                value={checkInDetails.PickUpThirdPartyName}
                                            />
                                        }
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        {relationshipList !== undefined && relationshipList !== null ? (
                                            <AdvancedDropDown
                                                data={relationshipList.map((i) => ({ label: i.varContactPersonName, value: i.numContactPersonID }))}
                                                ref={relationshipDropOffRef}
                                                placeHolder={"Relationship"}
                                                value={checkInDetails.PickUpThirdPartyRelationshipID}
                                                passByID={true}
                                                onChange={(e) => {
                                                    const checkInData = { ...checkInDetails }
                                                    checkInData.PickUpThirdPartyRelationshipID = e.value
                                                    setCheckInDetails(checkInData)
                                                }}
                                            />
                                        ) : null}
                                    </div>

                                    <div>
                                        {
                                            <InputMask
                                                alwaysShowMask="true"
                                                mask="\(999\)999\-9999"
                                                maskChar="_"
                                                onChange={(e) => {
                                                    const checkInData = { ...checkInDetails }
                                                    checkInData.PickUpThirdPartyContactNo = e.target.value
                                                    setCheckInDetails(checkInData)
                                                }}
                                                value={checkInDetails.PickUpThirdPartyContactNo}
                                            >
                                                {(inputProps) => (
                                                    <TextField
                                                        inputProps={{
                                                            pattern: true ? "^[0-9()-]+$" : "^[0-9()-]+$",
                                                        }}
                                                        {...inputProps}
                                                        type="tel"
                                                        required={true}
                                                        style={{ width: "100%" }}
                                                        label={
                                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                                Contact number
                                                                {/* {(true) ?
                                                            <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                            : null} */}
                                                            </div>
                                                        }
                                                        variant="standard"
                                                    />
                                                )}
                                            </InputMask>
                                        }
                                    </div>
                                </>
                            ) : showFieldsPickup === 2 ? (
                                <div style={{ width: "100%" }}>
                                    {linkedCustomers !== undefined && linkedCustomers !== null ? (
                                        <div style={{display:"flex", flexDirection:"column"}}>
                                                    <AdvancedDropDown
                                            data={linkedCustomers.map((i) => ({ label: i.varCustomerFirstName + " " + i.varCustomerLastName, value: i.numCustomerID }))}
                                            ref={linkedPickupRef}
                                            placeHolder={"Linked customers"}
                                            value={checkInDetails.PickUpPersonID}
                                            passByID={true}
                                            onChange={(e) => {
                                                const checkInData = { ...checkInDetails }
                                                checkInData.PickUpPersonID = e.value
                                                setCheckInDetails(checkInData)
                                            }}
                                        />

                                        <div style={{display:"flex",flexDirection:"column"}}>
                                            <p style={{margin:"0", fontWeight:"500"}}>Mobile: <span>{linkedCustomerContact?.phone}</span></p>
                                            <p style={{margin:"0", fontWeight:"500"}}>Work Phone: <span>{linkedCustomerContact?.work}</span></p>
                                            <p style={{margin:"0", fontWeight:"500"}}>Home: <span>{linkedCustomerContact?.home}</span></p>
                                        </div>
                                        </div>
                            
                                    ) : null}
                                </div>
                            ) : showFieldsPickup === 1 ? 
                                    <div style={{display:"flex",flexDirection:"column"}}>
                                        <p style={{margin:"0", fontWeight:"500"}}>Mobile: <span>{checkInDetails?.Customer?.varCustomerMobilePhone}</span></p>
                                        <p style={{margin:"0", fontWeight:"500"}}>Work Phone: <span>{checkInDetails?.Customer?.varCustomerWorkPhone}</span></p>
                                        <p style={{margin:"0", fontWeight:"500"}}>Home: <span>{checkInDetails?.Customer?.varCustomerHomePhone}</span></p>
                                    </div> : null}
                        </div>

                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", width: '95%', backgroundColor: "#192252", padding: "1rem", borderRadius: "0 0 12px 12px" }}>
                        <Button variant='contained' color='danger' disabled={(props.bookingDetails?.IsCheckedOut === true)? true : false} onClick={()=>{handleCheckOut()}}>Check Out</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default CheckOutModal;