import { useEffect, useState } from 'react';
import { MainNav, Breadcrumb } from '../../components/components'
import TopBar from '../../components/TopBar/TopBar';
import * as signalR from "@microsoft/signalr";

import styles from './MainLayout.module.scss'

import { Outlet } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import LocationContext from '../../utils/Context/LocationContext';

const MainLayout = (props) => {

    const [triggerNotification, setTriggerNotification] = useState(false);

    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
        .withUrl(`https://test.notifications.esypet.net/commonNotifications`, {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
        })
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect([0, 2000, 5000, 10000, 20000])
        .build();

        const startConnection = async () => {
            try {
                await connection.start();
                console.log("Connected to SignalR hub.");

                connection.on("ReceiveCallAgentJobNotification", (dataChanged) => {
                    console.log("ReceiveCallAgentJobNotification", dataChanged);
                    setTriggerNotification(true); 
                    props.onNotificationReceive(dataChanged);
                    toast.info("New records found. Click refresh to load the new data", {
                        autoClose: false,
                        closeOnClick: true, 
                        draggable: true, 
                    });
                });
            } catch (err) {
                setTimeout(startConnection, 5000);
            }
        };

        startConnection();

        return () => {
            connection.stop().then(() => console.log("Disconnected from SignalR hub."));
        };
    }, []);

    const [selectedLocation, setSelectedLocation] = useState(1)

    return (
        <div style={{display: 'flex'}}>
              <ToastContainer/>
            <MainNav/>     
            <div className={styles.bodyContainer}>
            <LocationContext.Provider value={{  location: selectedLocation }}>
                <TopBar locationOnChange={(e)=>{setSelectedLocation(e)}}/>  
                <Breadcrumb/>
                <Outlet context={{ triggerNotification, setTriggerNotification }} />   
                { props.children }
            </LocationContext.Provider>
            </div>
            
        </div>
    );
}

export default MainLayout;