import moment from "moment";

export function formatTemplate(template, data) {
  console.log("passeddataFormat", template, data)
  const regex = /{{\s*([a-zA-Z0-9.]+)\s*}}/g;
  const formattedTemplate = template.replace(regex, (match, path) => {
    const keys = path.split('.');
    let value = data;
    for (const key of keys) {
      if (Array.isArray(value) && value.length > 0) {
        // If the value is an array and not empty
        if (key === 'AllVaccines' || key === 'ExpiredVaccines' || key === 'Unexpired') {
          const filteredVaccines = key === 'ExpiredVaccines'
            ? value.filter(vaccine => vaccine.IsExpired === 1)
            : key === 'Unexpired'
              ? value.filter(vaccine => vaccine.IsExpired === 0)
              : value;
          
          if (filteredVaccines.length > 0) {
            const heading = key === 'AllVaccines'
              ? 'All Vaccine Records'
              : key === 'ExpiredVaccines'
                ? 'Expired Vaccine Records'
                : 'Unexpired Vaccine Records';
              
            const vaccines = filteredVaccines.map(vaccine => {

              const vaccineGivenDate = moment(vaccine.VaccineGivenDate).format('YYYY-MM-DD');
              const vaccineExpireDate = moment(vaccine.VaccineExpireDate).format('YYYY-MM-DD');

              return `<tr><td style="font-size:12px; padding: 8px; border: 1px solid #dddddd; width: auto;">${vaccine.VaccineName}</td><td style="font-size:12px; padding: 4px; border: 1px solid #dddddd; width: auto;">${vaccineGivenDate}</td><td style="font-size:12px; padding: 4px; border: 1px solid #dddddd; width: auto;">${vaccineExpireDate}</td><td style="font-size:12px; padding: 4px; border: 1px solid #dddddd; width: auto;"><a href="${process.env.REACT_APP_MEDIA_URL}vaccination_records/${vaccine.DocumentPath}" target="_blank">${vaccine.DocumentDisplayName}</a></td></tr>`;
            });
            return `<h5>${heading}</h5><table style="border-collapse: collapse; width: 100%;"><tr style="background-color: #f2f2f2;"><th style="font-size:14px; padding: 4px; border: 1px solid #dddddd; width: auto;">Vaccine Name</th><th style="font-size:14px; padding: 4px; border: 1px solid #dddddd; width: auto;">Given Date</th><th style="font-size:14px; padding: 4px; border: 1px solid #dddddd; width: auto;">Expire Date</th><th style="font-size:14px; padding: 8px; border: 1px solid #dddddd; width: auto;">Document</th></tr>${vaccines.join('')}</table>`;
          } else {
            return ''; // No vaccines to display
          }
        }
      } else if (value && typeof value === 'object' && value.hasOwnProperty(key)) {
        console.log("passeddataFormat!!", value[key])
        value = formatDate(value[key]);
      } else {
        return ''; // Property not found or invalid type
      }
    }
    return value === null ? '' : value;
  });
  return formattedTemplate;
}

const formatDate = (value) => {
  // Regular expression for matching ISO 8601 date strings (e.g., 2024-11-22T08:00:00)
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

  // Check if the value matches the ISO 8601 format
  if (typeof value === 'string' && isoDateRegex.test(value)) {
    const date:any = new Date(value);

    // Check if the value is a valid date
    if (!isNaN(date)) {
      const options:any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
      
      // Return the formatted string with weekday, month, and time
      return formattedDate;
    }
  }

  return value; // If it's not a date, return the original value
};
