import React, { useCallback, useEffect, useState } from "react"
import "@mobiscroll/react/dist/css/mobiscroll.min.css"
import {
    Eventcalendar,
    getJson,
    setOptions,
    CalendarNav,
    momentTimezone,
    SegmentedGroup,
    SegmentedItem,
    CalendarPrev,
    CalendarToday,
    CalendarNext,
    Popup,
    Button,
    MbscCalendarEvent,
} from "@mobiscroll/react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import moment from "moment-timezone"
import { addMinutes, calculateWeeklyTiming, convertCalTime, formatDateDisplay, formatDateTime } from "../../../../../utils/TimeHandler"
import axios from "axios"
import { axiosPrivate } from "../../../../../apis/axios"
import { ButtonGroup, CircularProgress, IconButton } from "@mui/material"
import useAuth from "../../../../../hooks/useAuth"
import { ArrowBack, ArrowForward, ContentCut, DarkMode, EditOff, Fullscreen, FullscreenExit, LightMode, ModeEdit, Person, Pets, Replay, Shower } from "@mui/icons-material"
import { Chip } from "../../../../../components"
import ViewSummary from "../AllCalendarBooking/ViewSummary"
import { Modal } from "../../../../../components/components"
import CloseButton from "../../../../../components/Modal/CloseButton/CloseButton"
import CheckInModalApp from "../../../../../components/ExtModals/CheckInModalApp"
import ViewCustomerApp from "../../../../../components/ExtModals/ViewCustomerApp"
import styles from "./AllCalendar.module.scss"
import stylesFl from "../../../../../pages/AdminPanel/Scheduler/Tabs/ScheduleTab/ScheduleTab.module.scss"
import ViewPetApp from "../../../../../components/ExtModals/ViewPetApp"
import { sendAppointmentStaticMessage, sendOutNotification } from "../../../../../utils/Messaging"
import ViewGroomerNote from "../../../../../components/ExtModals/ViewGroomerNote"

const randomColor = require("randomcolor")
momentTimezone.moment = moment

const AllCalendar = (props: {
    customerBooking: any
    servicePets: any
    store: any
    slotList: any
    viewSummary: CallableFunction
    location: any
    customer: any
    pet: any
    employee: any
    intervals: number
    toDate: any
    fromDate: any
}) => {
    setOptions({
        theme: "ios",
        themeVariant: "light",
    })

    const { auth } = useAuth() as any

    const [view, setView] = React.useState("month")
    // const [myEvents, setEvents] = React.useState([]) as any
    const [myEvents, setEvents] = useState([]) as any
    const [colorsList, setColorsList] = React.useState([]) as any
    const [resources, setResources] = useState([]) as any

    const getEventsAll = async (employee) => {
        const allResults = await Promise.all(employee.map((emp) => getSelectedTemplateEvents(emp?.employeeID)))
        console.log("all", allResults)
        const allColors = allResults.flatMap((result) => result?.colors || [])
        setColorsList(allColors)
    }

    useEffect(() => {
        if (props?.slotList.length > 0) {
            getEventsAll(props?.slotList)

            const tempEmp = [] as any
            props?.slotList.map((emp) => {
                tempEmp.push({ id: emp.employeeID, name: emp.name })
            })
            console.log("resources list", tempEmp)
            // setResources(tempEmp)
        } else {
            // setResources([])
        }

        console.log("all slots in cal", props?.slotList)
        getAllSlots(props?.slotList)
    }, [props?.slotList])

    useEffect(() => {
        console.log("ressss", resources)
    }, [resources])

    async function getAllSlots(allTimes) {
        let allAppointments = [] as any

        if (allTimes.length > 0) {
            console.log("fulllist appointments", allTimes)

            await axios
                .get(`${process.env.REACT_APP_API_URL}Appointment/GetAllScheduleAppointmnets`)
                .then((result) => {
                    if (result.status === 200) {
                        console.log("All-Appoinments - RAW", result)

                        result?.data.Appointments.map((app) => {
                            let startTming = calculateWeeklyTiming(app.dtApptStartTime)
                            let endTming = addMinutes(startTming, app.dtApptEndTime)

                            let objPure = {
                                start: app.dtApptStartTime,
                                end: app.dtApptEndTime,
                                title: `#${app.bookingId}: ${app.PetName} - ${app.varCustomerFirstName + " " + app.varCustomerLastName} - ${app.Services[0].varServiceItemName} | ${formatDateDisplay(
                                    app.dtApptStartTime
                                )} - ${formatDateDisplay(app.dtApptEndTime)} `,
                                color: "#f40000",
                                id: `${app.numAppointmentID}-employee`,
                                resource: app.EmployeeID,
                                resourceData: {
                                    petName: app.PetName,
                                    customerName: app.varCustomerFirstName + " " + app.varCustomerLastName,
                                    customer_id: app.numCustomerID,
                                    pet_id: app.PetID,
                                    serviceItemsLibraries: app.Services,
                                    date: app.date,
                                    employee_id: app.EmployeeID,
                                    numAppointmentID: app.numAppointmentID,
                                    EmployeeTimeSlotID: app.EmployeeTimeSlotID,
                                    EmployeeStartTime: app.EmployeeStartTime,
                                    EmployeeEndTime: app.EmployeeEndTime,
                                    DealerStartTime: app.DealerStartTime,
                                    DealerEndTime: app.DealerEndTime,
                                    DealerBookingID: app.DealerBookingID,
                                    EmployeeBookingID: app.EmployeeBookingID,
                                    numServiceItemID: app?.Services[0]?.numServiceItemID,
                                    sameGroomerBather: true,
                                    currentGroomer: `${app.varEmployeeFirstName} ${app.varEmployeeLastName}`,
                                    currentBather: `${app.DealerFirstName} ${app.DealerLastName}`,
                                    currentStatus: app?.apptStatus,
                                },
                            }

                            allAppointments.push(objPure)
                        })

                        allTimes.map((emps) => {
                            emps?.dates.map((dateOne) => {
                                dateOne?.slots.map((days, index) => {
                                    // console.log("debug c", days, emps)

                                    const earliestSlot = days?.slots?.reduce((earliest, slot) => (new Date(slot.start) < new Date(earliest.start) ? slot : earliest), days?.slots[0])
                                    const latestSlot = days?.slots?.reduce((latest, slot) => (new Date(slot.end) > new Date(latest.end) ? slot : latest), days?.slots[0])

                                    let employeeEvent = {
                                        start: calculateWeeklyTiming(earliestSlot.start),
                                        end: calculateWeeklyTiming(latestSlot.end),
                                        title: `Available`,
                                        color: "#0df400",
                                        id: `${index + 1}=${emps?.employeeID}=${days?.date}`,
                                        resource: emps?.employeeID,
                                        resourceData: {
                                            currentStatus: "available",
                                            date: days?.date,
                                            employee_id: emps?.employeeID,
                                            EmployeeStartTime: earliestSlot.start,
                                            EmployeeEndTime: latestSlot.end,
                                            slotDetails: days,
                                            petName: props?.servicePets[0]?.name,
                                            pet_id: props?.servicePets[0]?.id,
                                            customerName: props?.customerBooking.varCustomerFirstName + " " + props?.customerBooking.varCustomerLastName,
                                            customer_id: props?.customerBooking.numCustomerID,
                                        },
                                    }

                                    allAppointments.push(employeeEvent)
                                })
                            })
                        })
                    }
                    console.log("last apps", allAppointments)
                    setEvents(allAppointments)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            setEvents([])
        }
    }

    useEffect(() => {
        // getJson('https://trial.mobiscroll.com/events/?vers=5', (events) => {
        //     console.log(events)
        //     setEvents([
        //         {start: '2023-01-30T08:00:00.000Z', end: '2023-02-02T17:00:00.000Z', title: 'Business of Software Conference', color: '#ff6d42', id: 'mbsc_149'}
        //     ]);
        // }, 'jsonp');

        getAppointments()
    }, [props?.location, props?.intervals])

    const [timeInterval, setTimeInterval] = useState(10)

    const [eventSelectionList, setEventSelectionList] = useState([]) as any

    const [calView, setCalView] = React.useState({
        calendar: { labels: true },
    })

    const handleUploadICS = (event, fileName, appID) => {
        const icsData = createICSData(event)
        downloadICSFile(icsData, fileName, appID, event)
    }

    const downloadICSFile = (icsData, fileName, appID, event) => {
        const blob = new Blob([icsData], { type: "text/calendar" })
        const formData = new FormData()
        formData.append("folderpath", "email_attachments/ics_files")
        formData.append("method", "N")
        formData.append("deletingFileName", "-")
        formData.append("renameFileNames", "0")

        const blobname = fileName
        const renamedFile = new File([blob], `${blobname}.ics`, { type: blob.type })
        formData.append("files", renamedFile)

        axios
            .post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
            .then((response) => {
                console.log("Upload-Res", response.data.files[0])
                //Send Out Message
                let sendFiles = [
                    {
                        messageID: 1,
                        fileName: response.data.files[0]?.renamedFileName + ".ics",
                        filePath: `${process.env.REACT_APP_MEDIA_LOCAL_PATH}email_attachments/ics_files/${response.data.files[0]?.renamedFileName}.ics`,
                    },
                ]
                let code = "AC"
                sendAppointmentStaticMessage(appID, "Appointment", 1, "E", sendFiles, code)

                let fileAttachments = `<p>Click <a href="${process.env.REACT_APP_MEDIA_URL}email_attachments/ics_files/${response.data.files[0]?.renamedFileName}.ics" download="appointment.ics">here</a> to download the ICS file for your appointment.</p>`
                //Notification
                let notificationPostBody = {
                    notificationType: "success",
                    subject: "Appointment Confirmation Ref#:" + appID,
                    notificationMessage: event?.description + fileAttachments,
                    locationID: event?.locationID,
                    locationName: event?.location,
                    recipientType: "C",
                    recipientID: event?.recipientID,
                    recipientName: event?.recipientName,
                    isSeen: false,
                    isPushed: false
                }
                sendOutNotification(notificationPostBody)
            })
            .catch((error) => {
                console.error(error)
            })
        // const link = document.createElement('a');
        // link.href = URL.createObjectURL(blob);
        // link.download = `${fileName}.ics`;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
    }

    const createICSData = (event) => {
        const { title, description, startTime, endTime, location, email } = event
        const startDate = formatICSDate(new Date(startTime))
        const endDate = formatICSDate(new Date(endTime))

        return [
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "BEGIN:VEVENT",
            `UID:${createUUID()}`,
            `SUMMARY:${title}`,
            `DESCRIPTION:${description}`,
            `DTSTART:${startDate}`,
            `DTEND:${endDate}`,
            `LOCATION:${location}`,
            `ORGANIZER:mailto:${email}`,
            "END:VEVENT",
            "END:VCALENDAR",
        ].join("\n")
    }

    const formatICSDate = (date) => {
        return date.toISOString().replace(/-|:|\.\d{3}/g, "") + "Z"
    }

    const createUUID = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
            const r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8
            return v.toString(16)
        })
    }

    async function getEventsByTemplate(employeeID) {
        const result = await axiosPrivate.get(`Schedule/GetTimeSlotsByEmployeeID?LocationID=${props?.location === 0 ? "" : props?.location}&EmployeeID=${employeeID === 0 ? "" : employeeID}`)
        console.log("tempaltes list", result.data)
        return result.data
    }

    async function getAvailableEvent(employeeID, locationID) {
        const result = await axiosPrivate.get(`Schedule/GetAllEventsByLocationAndEmployee?LocationID=${locationID === 0 ? "" : locationID}&EmployeeID=${employeeID}`)
        console.log("available events", result.data)

        return result.data
    }

    const getSelectedTemplateEvents = (employeeID) => {
        return getEventsByTemplate(employeeID)
            .then((response) => {
                let res = response

                const createEvents = res.map((event, index) => {
                    return {
                        start: event?.StartTime,
                        end: event?.EndTime,
                        title: event?.EventName,
                        background: event?.EventColor,
                        resource: event?.EmployeeID,
                    }
                })

                console.log("colors", createEvents)

                return {
                    colors: createEvents,
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })

        // getAvailableEvent(employeeID, props?.location)
        //     .then((response) => {
        //         let res = response
        //         console.log("response events", response)

        //         setEventSelectionList(res)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //         toast.error("Something went wrong!")
        //     })
    }

    const getEvents = async (employee) => {
        const allResults = await Promise.all(employee.map((emp) => getSelectedTemplateEvents(emp?.id)))
        console.log("all", allResults)
        const allColors = allResults.flatMap((result) => result?.colors || [])
        setColorsList(allColors)
    }

    useEffect(() => {
        console.log("emp list all", props?.employee)
        if (props?.employee.length > 0) {
            getEvents(props?.employee)

            const tempEmp = [] as any
            props?.employee.map((emp) => {
                tempEmp.push({ id: emp.id, name: emp.component })
            })
            console.log("resources list", tempEmp)
            setResources(tempEmp)
        } else {
            setResources([])
        }
    }, [props?.employee])

    useEffect(() => {
        console.log("ressss", resources)
    }, [resources])

    async function getAllAppointments() {
        let payload = {
            locationID: props?.location,
            numCustomerID: props?.customer,
            numPetID: props?.pet,
            numServiceID: null,
            fromDate: "2024-01-01T07:38:03.660Z",
            toDate: "2024-06-30T07:38:03.660Z",
            employeeIDs: [],
        }
        console.log("wwww", payload)
        await axios
            .get(
                `${process.env.REACT_APP_API_URL}Appointment/GetBookingsDetailsForCalendar?FromDate=${props?.fromDate}&ToDate=${props?.toDate}&LocationID=${props?.location}&EmployeeID=${props?.employee}`
            )
            .then((result) => {
                if (result.status === 200) {
                    console.log("All-Appoinments", result)

                    let allAppointments = [] as any
                    console.log("apppp", result?.data.hasOwnProperty("Appointments"))

                    if (result?.data.hasOwnProperty("Appointments") && result?.data.Appointments.length > 0) {
                        result?.data.Appointments.map((app) => {
                            console.log("appoint detail", app)
                            if (app.EmployeeID !== app.DealerID) {
                                let appCol = randomColor({
                                    luminosity: "bright",
                                    alpha: 0.4,
                                })
                                let dealerStartTiming = calculateWeeklyTiming(app.DealerStartTime)
                                let dealerEndTiming = addMinutes(dealerStartTiming, app.DealerEndTime)

                                let dealerEvent = {
                                    start: app.DealerStartTime,
                                    end: app.DealerEndTime,
                                    title: `#${app.bookingId}: ${app.PetName} - ${app.varCustomerFirstName + " " + app.varCustomerLastName} - ${
                                        app.Services[0].varServiceItemName
                                    } | ${formatDateDisplay(app.DealerStartTime)} - ${formatDateDisplay(app.DealerEndTime)} `,
                                    color: appCol,
                                    id: `${app.numAppointmentID}-dealer`,
                                    resource: app.DealerID,
                                    resourceData: {
                                        petName: app.PetName,
                                        customerName: app.varCustomerFirstName + " " + app.varCustomerLastName,
                                        customer_id: app.numCustomerID,
                                        pet_id: app.PetID,
                                        serviceItemsLibraries: app.Services,
                                        date: app.date,
                                        employee_id: app.DealerID,
                                        numAppointmentID: app.numAppointmentID,
                                        EmployeeTimeSlotID: app.EmployeeTimeSlotID,
                                        EmployeeStartTime: app.EmployeeStartTime,
                                        EmployeeEndTime: app.EmployeeEndTime,
                                        DealerStartTime: app.DealerStartTime,
                                        DealerEndTime: app.DealerEndTime,
                                        DealerBookingID: app.DealerBookingID,
                                        EmployeeBookingID: app.EmployeeBookingID,
                                        numServiceItemID: app?.Services[0]?.numServiceItemID,
                                        sameGroomerBather: false,
                                        currentGroomer: `${app.varEmployeeFirstName} ${app.varEmployeeLastName}`,
                                        currentBather: `${app.DealerFirstName} ${app.DealerLastName}`,
                                        currentStatus: app?.apptStatus,
                                        notesPopup: app?.numGroomerNoteID,
                                    },
                                }
                                allAppointments.push(dealerEvent)

                                let employeeStartTiming = calculateWeeklyTiming(app.EmployeeStartTime)
                                let employeeEndTiming = addMinutes(employeeStartTiming, app.EmployeeEndTime)

                                let employeeEvent = {
                                    start: app.EmployeeStartTime,
                                    end: app.EmployeeEndTime,
                                    title: `#${app.bookingId}: ${app.PetName} - ${app.varCustomerFirstName + " " + app.varCustomerLastName} - ${
                                        app.Services[0].varServiceItemName
                                    } | ${formatDateDisplay(app.EmployeeStartTime)} - ${formatDateDisplay(app.EmployeeEndTime)} `,
                                    color: appCol,
                                    id: `${app.numAppointmentID}-employee`,
                                    resource: app.EmployeeID,
                                    resourceData: {
                                        petName: app.PetName,
                                        customerName: app.varCustomerFirstName + " " + app.varCustomerLastName,
                                        customer_id: app.numCustomerID,
                                        pet_id: app.PetID,
                                        serviceItemsLibraries: app.Services,
                                        date: app.date,
                                        employee_id: app.EmployeeID,
                                        numAppointmentID: app.numAppointmentID,
                                        EmployeeTimeSlotID: app.EmployeeTimeSlotID,
                                        EmployeeStartTime: app.EmployeeStartTime,
                                        EmployeeEndTime: app.EmployeeEndTime,
                                        DealerStartTime: app.DealerStartTime,
                                        DealerEndTime: app.DealerEndTime,
                                        DealerBookingID: app.DealerBookingID,
                                        EmployeeBookingID: app.EmployeeBookingID,
                                        numServiceItemID: app?.Services[0]?.numServiceItemID,
                                        sameGroomerBather: false,
                                        currentGroomer: `${app.varEmployeeFirstName} ${app.varEmployeeLastName}`,
                                        currentBather: `${app.DealerFirstName} ${app.DealerLastName}`,
                                        currentStatus: app?.apptStatus,
                                        notesPopup: app?.numGroomerNoteID,
                                    },
                                }
                                allAppointments.push(employeeEvent)
                            } else {
                                let employeeStartTiming = calculateWeeklyTiming(app.EmployeeStartTime)
                                let employeeEndTiming = addMinutes(employeeStartTiming, app.EmployeeEndTime)

                                let employeeEvent = {
                                    start: app.dtApptStartTime,
                                    end: app.dtApptEndTime,
                                    title: `#${app.bookingId}: ${app.PetName} - ${app.varCustomerFirstName + " " + app.varCustomerLastName} - ${
                                        app.Services[0].varServiceItemName
                                    } | ${formatDateDisplay(app.dtApptStartTime)} - ${formatDateDisplay(app.EmployeeEndTime)} `,
                                    color: "#f40000",
                                    id: `${app.numAppointmentID}-employee`,
                                    resource: app.EmployeeID,
                                    resourceData: {
                                        petName: app.PetName,
                                        customerName: app.varCustomerFirstName + " " + app.varCustomerLastName,
                                        customer_id: app.numCustomerID,
                                        pet_id: app.PetID,
                                        serviceItemsLibraries: app.Services,
                                        date: app.date,
                                        employee_id: app.EmployeeID,
                                        numAppointmentID: app.numAppointmentID,
                                        EmployeeTimeSlotID: app.EmployeeTimeSlotID,
                                        EmployeeStartTime: app.EmployeeStartTime,
                                        EmployeeEndTime: app.EmployeeEndTime,
                                        DealerStartTime: app.DealerStartTime,
                                        DealerEndTime: app.DealerEndTime,
                                        DealerBookingID: app.DealerBookingID,
                                        EmployeeBookingID: app.EmployeeBookingID,
                                        numServiceItemID: app?.Services[0]?.numServiceItemID,
                                        sameGroomerBather: true,
                                        currentGroomer: `${app.varEmployeeFirstName} ${app.varEmployeeLastName}`,
                                        currentBather: `${app.DealerFirstName} ${app.DealerLastName}`,
                                        currentStatus: app?.apptStatus,
                                        notesPopup: app?.numGroomerNoteID,
                                    },
                                }
                                allAppointments.push(employeeEvent)
                            }
                        })

                        console.log("fulllist appointments", allAppointments)
                        setEvents(allAppointments)
                    } else {
                        setEvents([])
                    }
                }
            })
            .catch((err) => {
                //console.log(err);
            })

        changeView({
            target: { value: "day" },
        })
    }

    const getAppointments = () => {
        getAllAppointments()
    }

    const showBooking = (id) => {
        console.log("Clicked", { id: id, status: true })
        props.viewSummary({ id: id, status: true })
    }

    const deleteEvent = (id) => {
        console.log("Delete Evnt", { id: id, status: true })
        let ev = myEvents.find((appointment) => appointment.resourceData.numAppointmentID === id)
        console.log("Eventsss", ev)

        console.log("dealer id", ev?.resourceData?.DealerBookingID)

        let updateOriginalGroomer = [
            {
                bookingID: ev?.resourceData?.DealerBookingID,
                timeSlotID: ev?.resourceData?.EmployeeTimeSlotID,
                serviceID: ev?.resourceData?.numServiceItemID,
                startTime: ev?.resourceData?.DealerStartTime,
                endTime: ev?.resourceData?.DealerEndTime,
                appointmentID: ev?.resourceData?.numAppointmentID,
                isBather: true,
                userID: 1,
            },
        ]
        axios
            .post(`${process.env.REACT_APP_API_URL}Appointment/PopulateBooking`, updateOriginalGroomer)
            .then(function (response) {
                console.log("app_success", response)

                if (response.status === 200) {
                    console.log("Appointment updated saved updateOriginalGroomer!")
                }
            })
            .catch(function (error) {
                console.log(error)
                console.log("Appointment updated failed!")
            })
    }

    const makeTheBooking = (slotsToBook) => {
        if (props.customerBooking.customerAddress.length > 0) {
            let billingAddress = props.customerBooking.customerAddress
                .filter((x) => x.bitIsBilling === true)
                .map((billingAddress, index) => {
                    return billingAddress
                })

            let reservationObj = {} as any

            if (slotsToBook?.slots.length > 1) {
                reservationObj = {
                    numSchID: 0,
                    varScheduleType: 0,
                    numPetID: props?.servicePets?.[0].id,
                    numSchedulingCustomerID: props?.customerBooking.numCustomerID,
                    numSchEventID: 0,
                    dtApptStartTime: require("moment")(slotsToBook?.slots[0]?.start).format("YYYY-MM-DDTHH:mm:ss"),
                    numApptDuration: 0,
                    numPrecedingScheduleID: 0,
                    numPrecedingEventID: 0,
                    numSucceedingEventID: 0,
                    numSucceedingSceduleID: 0,
                    numSchApptStatusID: 0,
                    bitActive: true,
                    numLocationID: props?.location,
                    dtApptEndTime: require("moment")(slotsToBook?.slots[0]?.end).format("YYYY-MM-DDTHH:mm:ss"),
                    apptStatus: "SCHEDULING",
                    numCustomerID: props?.customerBooking.numCustomerID,
                    numBillToCustomerID: props?.customerBooking.numCustomerID,
                    varBillToAddress1: billingAddress[0].varCustomerAddress1,
                    varBillToAddress2: billingAddress[0].varCustomerAddress2,
                    varBillToCity: billingAddress[0].varCustomerCity,
                    varBillToState: billingAddress[0].varCustomerState,
                    varBillToZip: billingAddress[0].varCustomerZip,
                    varTerms: "'Due on Receipt'",
                    numEmployeeID: auth?.employee?.numEmployeeID,
                    varEmployeeName: auth?.employee?.varEmployeeFirstName + " " + auth?.employee?.varEmployeeLastName,
                    customerPrefferedAppointmentNo: null,
                    schedulingBookings: [
                        {
                            timeSlotID: slotsToBook.slots[2].slotID,
                            serviceID: props?.servicePets?.[0].additionalService[0].id,
                            startTime: require("moment")(slotsToBook?.slots[2]?.start).format("YYYY-MM-DDTHH:mm:ss"),
                            endTime: require("moment")(slotsToBook?.slots[2]?.end).format("YYYY-MM-DDTHH:mm:ss"),
                            appointmentID: 0,
                            userID: 1,
                            IsBather: true,
                        },
                        {
                            timeSlotID: slotsToBook.slots[1].slotID,
                            serviceID: props?.servicePets?.[0].additionalService[0].id,
                            startTime: require("moment")(slotsToBook?.slots[1]?.start).format("YYYY-MM-DDTHH:mm:ss"),
                            endTime: require("moment")(slotsToBook?.slots[1]?.end).format("YYYY-MM-DDTHH:mm:ss"),
                            appointmentID: 0,
                            userID: 1,
                            IsBather: false,
                        },
                    ],
                }
            } else {
                reservationObj = {
                    numSchID: 0,
                    varScheduleType: 0,
                    numPetID: props?.servicePets?.[0].id,
                    numSchedulingCustomerID: props?.customerBooking.numCustomerID,
                    numSchEventID: 0,
                    dtApptStartTime: require("moment")(slotsToBook?.slots[0]?.start).format("YYYY-MM-DDTHH:mm:ss"),
                    numApptDuration: 0,
                    numPrecedingScheduleID: 0,
                    numPrecedingEventID: 0,
                    numSucceedingEventID: 0,
                    numSucceedingSceduleID: 0,
                    numSchApptStatusID: 0,
                    bitActive: true,
                    numLocationID: props?.location,
                    dtApptEndTime: require("moment")(slotsToBook?.slots[0]?.end).format("YYYY-MM-DDTHH:mm:ss"),
                    apptStatus: "SCHEDULING",
                    numCustomerID: props?.customerBooking.numCustomerID,
                    numBillToCustomerID: props?.customerBooking.numCustomerID,
                    varBillToAddress1: billingAddress[0].varCustomerAddress1,
                    varBillToAddress2: billingAddress[0].varCustomerAddress2,
                    varBillToCity: billingAddress[0].varCustomerCity,
                    varBillToState: billingAddress[0].varCustomerState,
                    varBillToZip: billingAddress[0].varCustomerZip,
                    varTerms: "'Due on Receipt'",
                    numEmployeeID: auth?.employee?.numEmployeeID,
                    varEmployeeName: auth?.employee?.varEmployeeFirstName + " " + auth?.employee?.varEmployeeLastName,
                    customerPrefferedAppointmentNo: null,
                    schedulingBookings: [
                        {
                            timeSlotID: slotsToBook.slots[0].slotID,
                            serviceID: props?.servicePets?.[0].additionalService[0].id,
                            startTime: require("moment")(slotsToBook?.slots[0]?.start).format("YYYY-MM-DDTHH:mm:ss"),
                            endTime: require("moment")(slotsToBook?.slots[0]?.end).format("YYYY-MM-DDTHH:mm:ss"),
                            appointmentID: 0,
                            userID: 1,
                            IsBather: false,
                        },
                    ],
                }
            }

            console.log("sent-res-Populate - Appointment", reservationObj)
            axios
                .post(`${process.env.REACT_APP_API_URL}Appointment/PopulateAppointment`, reservationObj)
                .then(function (response) {
                    console.log("app_success", response)
                    if (response.status === 200) {
                        toast.success("Appointment successfully saved!")

                        //Upload ICS

                        handleUploadICS(
                            {
                                title: "Appointment Confirmation REF# " + response.data?.outAppointmentID,
                                description: `<p>Appointment successfully scheduled for <strong>${props?.servicePets?.[0].name}</strong> at our grooming salon <strong>${
                                    props?.store.find((item) => item.id === props?.location)?.title
                                }</strong>.</p>`,
                                startTime: require("moment")(slotsToBook?.slots[0]?.start).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
                                endTime: require("moment")(slotsToBook?.slots[0]?.end).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
                                location: props?.store.find((item) => item.id === props?.location)?.title,
                                email: props?.store.find((item) => item.id === props?.location)?.email,
                                locationID: props?.location,
                                recipientID: props?.customerBooking.numCustomerID,
                                recipientName: props?.customerBooking.varCustomerFirstName + " " + props?.customerBooking?.varCustomerLastName,
                            },
                            `AppointmentConfirmationREF${response.data?.outAppointmentID}`,
                            response.data?.outAppointmentID
                        )

                        // const serPetList = [...servicePets]
                        // props.removeFromList?.(props?.servicePets?.[0].id)
                        // serPetList.splice(res_pet_index, 1)
                        // setServicePets(serPetList)
                        // if (servicePets.length > 0) {
                        //     setReservationPet(servicePets[0].id)
                        // } else {
                        //     setReservationPet("")
                        // }
                        // reservationPetRef.current?.handleClear()
                        // props.pastSelectedAppointment("")
                        // props.pastAppList([])
                        // setPastAppointmentList([])
                        // setSelectedPastAppointment("")

                        // setBookingDate(moment().format("YYYY-MM-DD"))
                        // setTimeOfDay(9)
                        // myRefTimeOFDay.current?.handleClear()
                        // setSlotEmployeeList([])
                        // setSelectedDate({ empIndex: "", dateIndex: "" })

                        // GetAllInvoicesByCustomerID(0, props.id)
                        //     .then((data) => {
                        //         console.log("customer inv", data)
                        //         if (data.length > 0) {
                        //             setIsInvAvailable(false)
                        //             setCustomerInvoiceID(data[0].numInvoiceID)
                        //         } else {
                        //             setIsInvAvailable(true)
                        //         }
                        //     })
                        //     .catch((error) => {
                        //         console.log("error", error)
                        //     })
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        } else {
            toast.success("No addresses ware found for the selected customer")
        }
        console.log("petsss", props?.servicePets)
    }

    const moveEvent = (event) => {
        console.log("movee", event)
        let ev = event?.event

        let newStartTime = ev?.start
        let newEndTime = ev?.end

        let updateOriginalGroomer = [
            {
                bookingID: ev?.resourceData?.DealerBookingID,
                timeSlotID: ev?.resourceData?.EmployeeTimeSlotID,
                serviceID: ev?.resourceData?.numServiceItemID,
                startTime: convertCalTime(newStartTime),
                endTime: addMinutes(convertCalTime(newStartTime), 10),
                appointmentID: ev?.resourceData?.numAppointmentID,
                isBather: true,
                userID: 1,
            },
            {
                bookingID: ev?.resourceData?.EmployeeBookingID,
                timeSlotID: ev?.resourceData?.EmployeeTimeSlotID,
                serviceID: ev?.resourceData?.numServiceItemID,
                startTime: addMinutes(convertCalTime(newStartTime), 10),
                endTime: convertCalTime(newEndTime),
                appointmentID: ev?.resourceData?.numAppointmentID,
                isBather: false,
                userID: 1,
            },
        ]

        console.log("payylod", updateOriginalGroomer)

        axios
            .post(`${process.env.REACT_APP_API_URL}Appointment/PopulateBooking`, updateOriginalGroomer)
            .then(function (response) {
                console.log("app_success", response)

                if (response.status === 200) {
                    console.log("Appointment updated successfully!")
                    toast.success("Appointment updated successfully!")
                }
            })
            .catch(function (error) {
                console.log(error)
                console.log("Appointment updated failed!")
                toast.success("Appointment updated failed!")
            })
    }

    const changeView = (event) => {
        let calView

        switch (event.target.value) {
            case "year":
                calView = {
                    calendar: { type: "year", timeCellStep: props?.intervals, timeLabelStep: props?.intervals },
                    schedule: {
                        startTime: "06:00",
                        endTime: "19:00",
                    },
                }
                break
            case "month":
                calView = {
                    calendar: { labels: true, timeCellStep: props?.intervals, timeLabelStep: props?.intervals },
                    schedule: {
                        startTime: "06:00",
                        endTime: "19:00",
                    },
                }
                break
            case "week":
                calView = {
                    schedule: { type: "week", timeCellStep: props?.intervals, timeLabelStep: props?.intervals, startTime: "06:00", endTime: "19:00" },
                }
                break
            case "day":
                calView = {
                    schedule: {
                        type: "day",
                        startTime: "06:00",
                        endTime: "19:00",
                        timeCellStep: props?.intervals,
                        timeLabelStep: props?.intervals,
                    },
                }
                break
        }

        setView(event.target.value)
        setCalView(calView)
    }
    const [fullScreen, setFullScreen] = useState(false)
    const [darkMode, setDarkMode] = useState(false)
    const [editableCalendar, setEditableCalendar] = useState(false)

    const customWithNavButtons = () => {
        return (
            <>
                <CalendarNav className="cal-header-nav" />
                <div className="cal-header-picker">
                    <SegmentedGroup value={view} onChange={changeView}>
                        <SegmentedItem value="year">Year</SegmentedItem>
                        <SegmentedItem value="month">Month</SegmentedItem>
                        <SegmentedItem value="week">Week</SegmentedItem>
                        <SegmentedItem value="day">Day</SegmentedItem>
                    </SegmentedGroup>
                </div>
                <CalendarPrev className="cal-header-prev" />
                <CalendarToday className="cal-header-today" />
                <CalendarNext className="cal-header-next" />

                <IconButton color="primary" onClick={() => setDarkMode(!darkMode)}>
                    {darkMode ? <DarkMode style={{ fontSize: "20px" }} /> : <LightMode style={{ fontSize: "20px" }} />}
                </IconButton>
                <IconButton color="primary" onClick={() => setFullScreen(!fullScreen)}>
                    {fullScreen ? <FullscreenExit /> : <Fullscreen />}
                </IconButton>

                <IconButton color="primary" onClick={() => setEditableCalendar(!editableCalendar)}>
                    {editableCalendar ? <ModeEdit /> : <EditOff />}
                </IconButton>
            </>
        )
    }

    const [isOpen, setOpen] = React.useState(false)
    const [anchor, setAnchor] = React.useState<any>(null)
    const [closeOnOverlay, setCloseOnOverlay] = React.useState(false)
    const [statusLoading, setStatusLoading] = React.useState(false)
    const [currentEvent, setCurrentEvent] = React.useState(null)
    const timerRef = React.useRef<any>(null)

    const [time, setTime] = React.useState("") as any
    const [serviceName, setServiceName] = React.useState("") as any
    const [petName, setPetName] = React.useState("") as any
    const [customer, setCustomer] = React.useState([]) as any
    const [customerID, setCustomerID] = React.useState(0) as any
    const [petID, setPetID] = React.useState(0) as any
    const [services, setServices] = React.useState([]) as any
    const [appID, setAppID] = React.useState("") as any
    const [eventAppointmentID, setEventAppointmentID] = React.useState("") as any
    const [startTime, setStartTime] = React.useState("") as any
    const [endTime, setEndTime] = React.useState("") as any
    const [currGroomer, setCurrGroomer] = React.useState("") as any
    const [currBather, setCurrBather] = React.useState("") as any
    const [groomerNotesPopup, setGroomerNotesPopup] = React.useState("") as any
    const [currentAppointmentStatus, setCurrentAppointmentStatus] = React.useState("") as any
    const [sameBatherGroomer, setSameBatherGroomer] = React.useState(false) as any
    const [isOpenCheckInOutModal, setIsOpenCheckInOutModal] = React.useState(false) as any
    const [isOpenViewCustomer, setIsOpenViewCustomer] = React.useState(false) as any
    const [isOpenViewPet, setIsOpenViewPet] = React.useState(false) as any
    const [getSlotDetails, setGetSlotDetails] = React.useState({}) as any

    const [selectedEvents, setSelectedEvents] = useState([]) as any

    const [groomerNotes, setGroomerNotes] = useState<any>([])
    const [isOpenGroomerNote, setIsOpenGroomerNote] = useState(false)

    useEffect(() => {
        console.log("selectedEvents", selectedEvents)
    }, [selectedEvents])

    const openGroomerNote = (notes) => {
        if (notes.length > 0) {
            if (notes[0]?.numGroomerNoteID === null) {
                toast.info("Groomer Note - Still In Progress!")
            } else {
                setGroomerNotes(notes)
                setIsOpenGroomerNote(true)
            }
        } else {
            toast.info("No groomer note added for the selected appointment")
        }
    }

    const openTooltip = React.useCallback(
        (args, closeOption) => {
            const event = args.event

            const resource = myEvents.find((evt) => evt.id === event.id)
            // const time = formatDate('hh:mm A', new Date(event.start)) + ' - ' + formatDate('hh:mm A', new Date(event.end));

            if (resource?.resourceData?.currentStatus === "available") {
                setCurrentAppointmentStatus(resource?.resourceData?.currentStatus)
                setStartTime(resource?.start)
                setEndTime(resource?.end)
                setGetSlotDetails(resource?.resourceData?.slotDetails)
                setGroomerNotesPopup(resource?.resourceData?.notesPopup)
                setCustomer(resource?.resourceData?.customerName)
                setCustomerID(resource?.resourceData?.customer_id)
                setPetName(resource?.resourceData?.petName)
                setPetID(resource?.resourceData?.pet_id)
            } else {
                setCurrentEvent(event)
                setTime(resource?.resourceData?.date)
                // console.log("timeee", resource?.resource?.serviceItemsLibraries[0])
                setServiceName(resource?.resourceData?.serviceItemsLibraries.length > 0 ? resource?.resourceData?.serviceItemsLibraries[0]?.varServiceItemName : "Not Set")
                setCurrentAppointmentStatus(resource?.resourceData?.currentStatus)
                setStartTime(resource?.start)
                setEndTime(resource?.end)
                setCustomer(resource?.resourceData?.customerName)
                setCustomerID(resource?.resourceData?.customer_id)
                setPetName(resource?.resourceData?.petName)
                setPetID(resource?.resourceData?.pet_id)
                setServices(resource?.resourceData?.serviceItemsLibraries)
                setAppID(resource?.id)
                setEventAppointmentID(resource?.resourceData?.numAppointmentID)
                setCurrGroomer(resource?.resourceData?.currentGroomer)
                setCurrBather(resource?.resourceData?.currentBather)
                setSameBatherGroomer(resource?.resourceData?.sameGroomerBather)
                setGroomerNotesPopup(resource?.resourceData?.notesPopup)
            }
            // if (event.confirmed) {
            //     setStatus('Confirmed');
            //     setButtonText('Cancel appointment');
            //     setButtonType('warning');
            // } else {
            //     setStatus('Canceled');
            //     setButtonText('Confirm appointment');
            //     setButtonType('success');
            // }

            // setBgColor('resource.color');
            // setInfo('event.title' + ', Age: ' + 'event.age');

            // setReason('event.reason');
            // setLocation('event.location');

            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }

            setAnchor(args.domEvent.currentTarget || args.domEvent.target)
            setCloseOnOverlay(closeOption)
            setOpen(true)
        },
        [myEvents]
    )

    const eventDeleted = (e) => {
        console.log("eventtt", e)
    }

    const onEventClick = (e) => {
        console.log("eventtt", e)
    }

    const assignPrev = (event) => {
        let tempEmpArr = resources.map((e) => e.id)
        console.log("ress list", resources, tempEmpArr.indexOf(event?.resource))

        console.log(event?.id, myEvents)

        if (tempEmpArr.indexOf(event?.resource) === 0) {
            toast.warning("first element")
        } else {
            let tmpAllEvents = [...myEvents] as any

            tmpAllEvents?.map((evt, index) => {
                if (evt?.id === event?.id) {
                    tmpAllEvents[index].resource = resources[tempEmpArr.indexOf(event?.resource) - 1]?.id
                }
            })

            setEvents(tmpAllEvents)
        }
    }

    const assignNext = (event) => {
        let tempEmpArr = resources.map((e) => e.id)
        console.log("ress list", resources, tempEmpArr.indexOf(event?.resource))
        console.log(event?.id, myEvents)

        if (resources?.length - 1 === tempEmpArr.indexOf(event?.resource)) {
            toast.warning("last element")
        } else {
            let tmpAllEvents = [...myEvents] as any

            tmpAllEvents?.map((evt, index) => {
                if (evt?.id === event?.id) {
                    tmpAllEvents[index].resource = resources[tempEmpArr.indexOf(event?.resource) + 1]?.id
                }
            })

            setEvents(tmpAllEvents)
        }
    }

    const selfAssignBath = (id) => {
        let e = myEvents.find((appointment) => appointment.resourceData.numAppointmentID === id)
        console.log("authhh", auth)
        console.log("eventtt", e)

        let dtApptStartTime = e?.start
        let dtApptEndTime = e?.end

        let startTime = moment(dtApptStartTime)
        let endTime = moment(dtApptEndTime)

        let batherStartingTime = moment(e?.resourceData?.EmployeeStartTime)
        let batherEndingTime = moment(e?.resourceData?.EmployeeEndTime)

        let dealerStartingTime = moment(e?.resourceData?.DealerStartTime)
        let dealerEndingTime = moment(e?.resourceData?.DealerEndTime)

        console.log("batherStartingTime", batherStartingTime, batherEndingTime)
        console.log("dealerStartingTime", dealerStartingTime, dealerEndingTime)

        let batherAddPayload = [
            {
                bookingID: e?.resourceData?.DealerBookingID,
                timeSlotID: 1002,
                serviceID: e?.resourceData?.numServiceItemID,
                startTime: dealerStartingTime.format("YYYY-MM-DDTHH:mm:ss"),
                endTime: dealerEndingTime.format("YYYY-MM-DDTHH:mm:ss"),
                appointmentID: e?.resourceData?.numAppointmentID,
                isBather: true,
                userID: 1,
            },
        ]

        let groomerAddPayload = [
            {
                bookingID: e?.resourceData?.EmployeeBookingID,
                timeSlotID: e?.resourceData?.EmployeeTimeSlotID,
                serviceID: e?.resourceData?.numServiceItemID,
                startTime: batherStartingTime.format("YYYY-MM-DDTHH:mm:ss"),
                endTime: batherEndingTime.format("YYYY-MM-DDTHH:mm:ss"),
                appointmentID: e?.resourceData?.numAppointmentID,
                isBather: false,
                userID: 1,
            },
        ]

        console.log("payload", groomerAddPayload, batherAddPayload)

        axios
            .post(`${process.env.REACT_APP_API_URL}Appointment/PopulateBooking`, batherAddPayload)
            .then(function (response) {
                console.log("app_success", response)

                if (response.status === 200) {
                    console.log("Appointment updated saved batherAddPayload!")
                    axios
                        .post(`${process.env.REACT_APP_API_URL}Appointment/PopulateBooking`, groomerAddPayload)
                        .then(function (response) {
                            console.log("app_success", response)
                            if (response.status === 200) {
                                console.log("Appointment updated saved groomerAddPayload!")
                                toast.success("Appointment updated saved!")
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                            console.log("Appointment updated failed!")
                        })
                }
            })
            .catch(function (error) {
                console.log(error)
                console.log("Appointment updated failed!")
            })
    }

    const handleSelectedEventsChange = (selected) => {
        setSelectedEvents(selected.events)
    }

    const onEventHoverIn = React.useCallback(
        (args, closeOption) => {
            openTooltip(args, false)
        },
        [openTooltip]
    )

    const onEventHoverOut = React.useCallback(() => {
        timerRef.current = setTimeout(() => {
            setOpen(false)
        }, 200)
    }, [])

    const onMouseEnter = React.useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
    }, [])

    const onMouseLeave = React.useCallback(() => {
        timerRef.current = setTimeout(() => {
            setOpen(false)
        }, 200)
    }, [])

    async function getAppStatus(appID) {
        const result = await axiosPrivate.get(`Appointment/GetAppointmentStatusByID?AppointmentID=${appID.split("-")[0]}`)
        return result.data
    }

    const getCurrentAppointmentStatus = (appID) => {
        setStatusLoading(true)
        getAppStatus(appID)
            .then((res) => {
                console.log("app curr status", res[0]?.apptStatus)
                setCurrentAppointmentStatus(res[0]?.apptStatus)
                setStatusLoading(false)
            })
            .catch(function (error) {
                console.log(error)
                toast.error("Appointment status update failed!!")
                setStatusLoading(false)
            })
    }

    const [viewSummaryModal, setViewSummaryModal] = useState(false)

    const handleEventCreated = useCallback((args) => {
        console.log(args)
        args.event.title = "New Reservation"
    }, [])

    return (
        <div style={{ borderRadius: "10px" }}>
            <ToastContainer />
            <div className={fullScreen ? stylesFl.fullScreen : stylesFl.notfullScreen}>
                <Eventcalendar
                    themeVariant={darkMode ? "dark" : "light"}
                    resources={resources}
                    eventDelete={true}
                    renderHeader={customWithNavButtons}
                    height={800}
                    view={calView}
                    data={myEvents}
                    cssClass="md-switching-view-cont"
                    onEventHoverIn={onEventHoverIn}
                    onEventHoverOut={onEventHoverOut}
                    dataTimezone="America/Chicago"
                    displayTimezone="America/Chicago"
                    onEventDeleted={eventDeleted}
                    timezonePlugin={momentTimezone}
                    colors={colorsList}
                    onEventDragEnd={(e) => moveEvent(e)}
                    dragToMove={editableCalendar}
                    clickToCreate={editableCalendar}
                    onEventCreate={handleEventCreated}
                    onEventClick={onEventClick}
                    onSelectedEventsChange={handleSelectedEventsChange}
                />
            </div>
            <Modal open={viewSummaryModal} borderless={true}>
                <CloseButton onClick={() => setViewSummaryModal(false)} />
                <ViewSummary id={appID.split("-")[0]} />
            </Modal>

            {isOpenCheckInOutModal ? (
                <CheckInModalApp bookingID={appID.split("-")[0]} isOpenCheckInModal={isOpenCheckInOutModal} setIsOpenCheckInModal={(value) => setIsOpenCheckInOutModal(value)} />
            ) : null}

            {isOpenViewCustomer ? <ViewCustomerApp customerID={customerID} setIsOpenModal={(value) => setIsOpenViewCustomer(value)} isOpenModal={isOpenViewCustomer} /> : null}
            {isOpenViewPet ? <ViewPetApp petID={petID} setIsOpenModal={(value) => setIsOpenViewPet(value)} isOpenModal={isOpenViewPet} /> : null}

            <ViewGroomerNote isOpenViewModal={isOpenGroomerNote} setIsOpenViewModal={(value) => setIsOpenGroomerNote(value)} notes={groomerNotes} />

            <Popup display="anchored" isOpen={isOpen} anchor={anchor} touchUi={false} showOverlay={false} contentPadding={false} closeOnOverlayClick={closeOnOverlay} width={400} cssClass="md-tooltip">
                {currentAppointmentStatus === "available" ? (
                    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        <div className="md-tooltip-header" style={{ backgroundColor: "white", padding: "10px 15px", display: "flex", justifyContent: "space-between" }}>
                            <span className="md-tooltip-name-age" style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                                New Reservation
                            </span>
                            <span className="md-tooltip-time">
                                <Chip fontWeight={600} size="medium" customBackgroundColor={"#27ae60"} customBorder={"#3498db"} customBorderRadius={"16px"} fontColor={"#ffffff"} label={"Available"} />
                            </span>
                        </div>
                        <div className="md-tooltip-info" style={{ padding: "0 15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", margin: "5px 0" }}>
                                <p style={{ color: "#343434", fontWeight: "600" }}>Start: {formatDateDisplay(startTime)}</p>
                                <p style={{ color: "#343434", fontWeight: "600" }}>End: {formatDateDisplay(endTime)}</p>
                            </div>

                            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 2fr)", gridColumnGap: "2px" }}>
                                <div style={{ marginBottom: "5px", borderRadius: "5px 0 0 5px", padding: "5px", backgroundColor: "#fff", display: "flex", alignItems: "center", gap: "6px" }}>
                                    <div>
                                        <Person style={{ fontSize: "30px" }} />
                                    </div>
                                    <div>
                                        <p style={{ margin: 0, fontWeight: 600 }}>Customer</p>
                                        <span className={styles.linkClick} onClick={() => setIsOpenViewCustomer(true)}>
                                            {customer}
                                        </span>
                                    </div>
                                </div>
                                <div style={{ marginBottom: "5px", borderRadius: "0 5px 5px 0", padding: "5px", backgroundColor: "#fff", display: "flex", alignItems: "center", gap: "6px" }}>
                                    <div>
                                        <Pets style={{ fontSize: "24px", paddingLeft: "4px", paddingRight: "2px" }} />
                                    </div>
                                    <div>
                                        <p style={{ margin: 0, fontWeight: 600 }}>Pet</p>
                                        <span className={styles.linkClick} onClick={() => setIsOpenViewPet(true)}>
                                            {petName}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 2fr)", gridColumnGap: "2px", marginTop: "5px" }}>
                                <div style={{ marginBottom: "5px", borderRadius: "5px 0 0 5px", padding: "5px", backgroundColor: "#fff", display: "flex", alignItems: "center", gap: "6px" }}>
                                    <div>
                                        <ContentCut style={{ fontSize: "22px", paddingLeft: "6px", paddingRight: "2px" }} />
                                    </div>
                                    <div>
                                        <p style={{ margin: 0, fontWeight: 600 }}>Groomer</p>
                                        <span>{currGroomer}</span>
                                    </div>
                                </div>
                                <div style={{ marginBottom: "5px", borderRadius: "0 5px 5px 0", padding: "5px", backgroundColor: "#fff", display: "flex", alignItems: "center", gap: "6px" }}>
                                    <div>
                                        <Shower style={{ fontSize: "30px" }} />
                                    </div>
                                    <div>
                                        <p style={{ margin: 0, fontWeight: 600 }}>Bather</p>
                                        <span>{currBather}</span>
                                    </div>
                                </div>
                            </div> */}

                            <ButtonGroup style={{ margin: "4px 0 8px 0" }}>
                                <Button color="primary" variant="standard">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <p onClick={() => makeTheBooking(getSlotDetails)} style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1em", color: "#fff", padding: "6px 0" }}>
                                            Make Reservation
                                        </p>
                                    </div>
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                ) : (
                    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        <div className="md-tooltip-header" style={{ backgroundColor: "white", padding: "10px 15px", display: "flex", justifyContent: "space-between" }}>
                            <span className="md-tooltip-name-age" style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                                {services.map((service) => {
                                    return <Chip variant="outlined" fontWeight={600} size="medium" fontColor={"#000"} label={service.varServiceItemName} />
                                })}
                            </span>
                            <span className="md-tooltip-time">
                                {statusLoading ? (
                                    <Chip
                                        fontWeight={600}
                                        size="medium"
                                        customBackgroundColor={"#3498db"}
                                        customBorder={"#3498db"}
                                        customBorderRadius={"16px"}
                                        fontColor={"#ffffff"}
                                        label={"Loading..."}
                                    />
                                ) : currentAppointmentStatus === "SCHEDULING" ? (
                                    <Chip
                                        fontWeight={600}
                                        deleteIcon={<Replay style={{ fontSize: "20px", color: "#000" }} />}
                                        onDelete={() => getCurrentAppointmentStatus(appID)}
                                        size="medium"
                                        customBackgroundColor={"#3498db"}
                                        customBorder={"#3498db"}
                                        customBorderRadius={"16px"}
                                        fontColor={"#ffffff"}
                                        label={"Upcoming"}
                                    />
                                ) : currentAppointmentStatus === "ONGOING" ? (
                                    <Chip
                                        fontWeight={600}
                                        deleteIcon={<Replay style={{ fontSize: "20px", color: "#000" }} />}
                                        onDelete={() => getCurrentAppointmentStatus(appID)}
                                        size="medium"
                                        customBackgroundColor={"#f36b40"}
                                        customBorder={"#f36b40"}
                                        customBorderRadius={"16px"}
                                        fontColor={"#ffffff"}
                                        label={"Ongoing"}
                                    />
                                ) : currentAppointmentStatus === "PICKUP-READY" ? (
                                    <Chip
                                        fontWeight={600}
                                        deleteIcon={<Replay style={{ fontSize: "20px", color: "#000" }} />}
                                        onDelete={() => getCurrentAppointmentStatus(appID)}
                                        size="medium"
                                        customBackgroundColor={"#f39c12"}
                                        customBorder={"#f39c12"}
                                        customBorderRadius={"16px"}
                                        fontColor={"#ffffff"}
                                        label={"Pickup-Ready"}
                                    />
                                ) : currentAppointmentStatus === "PAYMENT-MADE" ? (
                                    <Chip
                                        fontWeight={600}
                                        deleteIcon={<Replay style={{ fontSize: "20px", color: "#000" }} />}
                                        onDelete={() => getCurrentAppointmentStatus(appID)}
                                        size="small"
                                        customBackgroundColor={"#27ae60"}
                                        customBorder={"#27ae60"}
                                        customBorderRadius={"16px"}
                                        fontColor={"#ffffff"}
                                        label={"Payment-made"}
                                    />
                                ) : currentAppointmentStatus === "CHECKED-OUT" ? (
                                    <Chip
                                        fontWeight={600}
                                        deleteIcon={<Replay style={{ fontSize: "20px", color: "#000" }} />}
                                        onDelete={() => getCurrentAppointmentStatus(appID)}
                                        size="small"
                                        customBackgroundColor={"#e74c3c"}
                                        customBorder={"#e74c3c"}
                                        customBorderRadius={"16px"}
                                        fontColor={"#ffffff"}
                                        label={"Checked-Out"}
                                    />
                                ) : (
                                    <Chip
                                        fontWeight={600}
                                        deleteIcon={<Replay style={{ fontSize: "20px", color: "#000" }} />}
                                        onDelete={() => getCurrentAppointmentStatus(appID)}
                                        size="small"
                                        customBackgroundColor={"#e74c3c"}
                                        customBorder={"#e74c3c"}
                                        customBorderRadius={"16px"}
                                        fontColor={"#ffffff"}
                                        label={currentAppointmentStatus}
                                    />
                                )}
                            </span>
                        </div>
                        <div className="md-tooltip-info" style={{ padding: "0 15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", margin: "5px 0" }}>
                                <p style={{ color: "#343434", fontWeight: "600" }}>Start: {formatDateDisplay(startTime)}</p>
                                <p style={{ color: "#343434", fontWeight: "600" }}>End: {formatDateDisplay(endTime)}</p>
                            </div>

                            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 2fr)", gridColumnGap: "2px" }}>
                                <div style={{ marginBottom: "5px", borderRadius: "5px 0 0 5px", padding: "5px", backgroundColor: "#fff", display: "flex", alignItems: "center", gap: "6px" }}>
                                    <div>
                                        <Person style={{ fontSize: "30px" }} />
                                    </div>
                                    <div>
                                        <p style={{ margin: 0, fontWeight: 600 }}>Customer</p>
                                        <span className={styles.linkClick} onClick={() => setIsOpenViewCustomer(true)}>
                                            {customer}
                                        </span>
                                    </div>
                                </div>
                                <div style={{ marginBottom: "5px", borderRadius: "0 5px 5px 0", padding: "5px", backgroundColor: "#fff", display: "flex", alignItems: "center", gap: "6px" }}>
                                    <div>
                                        <Pets style={{ fontSize: "24px", paddingLeft: "4px", paddingRight: "2px" }} />
                                    </div>
                                    <div>
                                        <p style={{ margin: 0, fontWeight: 600 }}>Pet</p>
                                        <span className={styles.linkClick} onClick={() => setIsOpenViewPet(true)}>
                                            {petName}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 2fr)", gridColumnGap: "2px", marginTop: "5px" }}>
                                <div style={{ marginBottom: "5px", borderRadius: "5px 0 0 5px", padding: "5px", backgroundColor: "#fff", display: "flex", alignItems: "center", gap: "6px" }}>
                                    <div>
                                        <ContentCut style={{ fontSize: "22px", paddingLeft: "6px", paddingRight: "2px" }} />
                                    </div>
                                    <div>
                                        <p style={{ margin: 0, fontWeight: 600 }}>Groomer</p>
                                        <span>{currGroomer}</span>
                                    </div>
                                </div>
                                <div style={{ marginBottom: "5px", borderRadius: "0 5px 5px 0", padding: "5px", backgroundColor: "#fff", display: "flex", alignItems: "center", gap: "6px" }}>
                                    <div>
                                        <Shower style={{ fontSize: "30px" }} />
                                    </div>
                                    <div>
                                        <p style={{ margin: 0, fontWeight: 600 }}>Bather</p>
                                        <span>{currBather}</span>
                                    </div>
                                </div>
                            </div>

                            <ButtonGroup style={{ margin: "4px 0 8px 0" }}>
                                <Button color="primary" variant="standard">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <p
                                            onClick={() => {
                                                setViewSummaryModal(true)
                                            }}
                                            style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1em", color: "#fff", padding: "6px 0" }}
                                        >
                                            View
                                        </p>
                                    </div>
                                </Button>
                                <Button color="primary" variant="standard">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <p
                                            onClick={() => {
                                                setIsOpenCheckInOutModal(true)
                                            }}
                                            style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1em", color: "#fff", padding: "6px 0" }}
                                        >
                                            Check In
                                        </p>
                                    </div>
                                </Button>
                                {/* {appID.split("-")[1] === "employee" && sameBatherGroomer && serviceName.toLowerCase() !== "bath" ? (
                                    <Button color="success" variant="standard" onClick={() => selfAssignBath(eventAppointmentID)}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <p style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1em", color: "#fff" }}>Self Assign Bath</p>
                                        </div>
                                    </Button>
                                ) : null} */}
                                {groomerNotesPopup === null ? null : (
                                    <Button color="warning" variant="standard" onClick={() => openGroomerNote([{ numGroomerNoteID: groomerNotesPopup }])}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <p style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1em", color: "#fff" }}>View Note</p>
                                        </div>
                                    </Button>
                                )}

                                {appID.split("-")[1] === "dealer" ? (
                                    <>
                                        <Button color="danger" variant="standard" onClick={() => deleteEvent(eventAppointmentID)}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <p style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1em", color: "#fff" }}>ReAssign to Groomer</p>
                                            </div>
                                        </Button>

                                        <Button color="success" variant="standard" onClick={() => assignPrev(currentEvent)}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <ArrowBack style={{ fontSize: "26px", textAlign: "center" }} />
                                            </div>
                                        </Button>

                                        <Button color="success" variant="standard" onClick={() => assignNext(currentEvent)}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <ArrowForward style={{ fontSize: "26px", textAlign: "center" }} />
                                            </div>
                                        </Button>
                                    </>
                                ) : null}
                            </ButtonGroup>

                            {/*TODO: Add condition for user type === bather the can undertake*/}
                        </div>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default AllCalendar
