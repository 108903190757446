import  { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import {
  TextField,
  Grid,
  Checkbox,
  Pagination,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  FormControlLabel,
  Button as MUIButton,
  Input,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import moment from 'moment';
import { Facebook as FacebookIcon, GetApp as DownloadIcon, Share as ShareIcon, Search, Place, MobileScreenShare, InfoOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { axiosPrivate } from '../../../apis/axios';
import AdvancedDropDown from '../../../components/AdvancedDropDown/AdvancedDropDown';
import { Avatar, Button, CloseButton } from '../../../components';
import { Modal, Chip as LocationChip } from '../../../components/components';
import styles from "./Inquiry.module.scss"
import debouce from "lodash.debounce"
import { formatPhone } from '../../../utils/PhoneFormatter';
import { Truncate } from '../../../utils/Validation';
import Swal from 'sweetalert2';
import useAuth from '../../../hooks/useAuth';
import { sendOutNotification } from '../../../utils/Messaging';


const NewBookingInquiry = () => {
  const [pageSize, setPageSize] = useState("6")
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    PetName: "",
    LocationID: null,
    FromDate: moment().subtract(1, 'months').format("YYYY-MM-DD"),
    ToDate: moment().format("YYYY-MM-DD"),
    PageNumber: 1,
    PageSize: pageSize,
    EmployeeID: null,
    ServiceID: null,
    PetBreedID: null,
    PetTypeID: null,
  });

  const { auth } = useAuth() as any

  const [petData, setPetData] = useState([]) as any;
  const [totalPages, setTotalPages] = useState(1) as any;
  const [selectedImages, setSelectedImages] = useState([]) as any;
  const [isLoading, setIsLoading] = useState(false);
  const [allLocations, setAllLocations] = useState([]) as any;
  const [selectAll, setSelectAll] = useState(false);
  const [beforeChecked, setBeforeChecked] = useState(false);
  const [afterChecked, setAfterChecked] = useState(false);
  const [isOpenCustomerModal, setIsOpenCustomerModal] = useState(false);

  const [petTypes, setPetTypes] = useState<any>([])
  const [petType, setPetType] = useState<any>("")
  const breedRef = useRef<any>()
  const serviceRef = useRef<any>()
  const typeRef = useRef<any>()
  const locationRef = useRef<any>()
  const employeeRef = useRef<any>()
  const searchBoxRef = useRef<any>()
  const [petSizes, setPetSizes] = useState<any>([])
  const [locationEmployees, setLocationEmployees] = useState<any>([])
  const [petBreeds, setPetBreeds] = useState<any>([])
  const [petServices, setPetServices] = useState<any>([])
  const [searchValue, setSearchValue] = useState("")
  const [searchData, setSearchData] = useState([] as any)
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [selectedCut, setSelectedCut] = useState<any>({}) as any
  const [customerFilteredPets, setCustomerFilteredPets] = useState<any>([])
  const [isOpenShareFBModal, setIsOpenShareFBModal] = useState(false);
  const [msg, setMsg] = useState<any>(
    "Your pet deserves to look their best! 🐾✨ Check out these adorable groom transformations from our happy customers. Ready to give your furry friend a fresh new look? Visit us at https://test.customer.esypet.net and book your appointment today!\n\n#PetGrooming #PetCare #FurryFriends #GroomingSalon #DogGrooming #CatGrooming #PetSpa #HealthyPets #PamperedPets #FreshLook #PetMakeover #HappyPets #LocalPetServices #BestInPetCare #PetLove #PetTransformation #BookYourAppointment #PetWellness"
  );
  const [tempLocations, setTempLocations] = useState<any>([{ name: 'Houston - ESyPet Pet Grooming', value: 1, pageID:"414892665036830" }])
  const [shareLocation, setShareLocation] = useState(1)
  const shareLocationRef = useRef<any>()
  const [pageId, setPageId] = useState(''); 
  const [accessToken, setAccessToken] = useState('EAAVVlgIx9LEBO1WY1ZAZCyf2gIyXzPqng9mVIpMJzzRd5oA6Uf3RZBWTR3FPkpiYvC3WMSrYn4fWD6e2dfZB3ToLTPUMmdzYrqZCnnhnRQxVUD04kTB53QB2n9jEGWUhCEGMslThk4bLhCycq983CiHkyhIa96FvZCczIyTV1VrOWWZCd4QxpFDJ9Jrqaqj4nSHhzf1AcTG3sAMR2nWdM1f8NqZBokaGYAKND1m6we1AZBQZDZD'); 
  const [uploading, setUploading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target || e;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchData = async () => {
    setIsLoading(true)
    console.log("Sent - formData", formData)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Schedule/GetPetDetailsForGallery`, {
        params: formData,
      });
      setPetData(response.data.data);
      setTotalPages(response.data.totalPages);
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false)
    }
  };

  async function getPetTypes(signal) {
    const result = await axiosPrivate.get(`PetType/GetAll`, {
        signal: signal,
    })
    return result.data.petType
}

async function getSizes(id) {
  const result = await axiosPrivate.get(`PetSize/GetAllByPetTypeID/${id}`, {
      headers: { "Content-Type": "application/json" },
  })
  return result.data
}

async function getBreedByPetType(id) {
  const result = await axiosPrivate.get(`PetBreed/GetAllByPetTypeID/${id}`, {
      headers: { "Content-Type": "application/json" },
  })
  return result.data
}

async function getServicesByPetType(id) {
const result = await axiosPrivate.get(`ServiceItems/GetServiceItemsByPetTypeID/${id}`, {
    headers: { "Content-Type": "application/json" },
})
return result.data
}
async function getEmployeeByLocationID(id) {
const result = await axiosPrivate.get(`Schedule/GetEmployeesByLocationID?LocationID=${id}`, {
    headers: { "Content-Type": "application/json" },
})
return result.data
}

async function getData(searchQuery) {
  const result = await axiosPrivate.post(
      "Customer/Search",
      JSON.stringify({
          page: 0,
          pageSize: 0,
          searchText: searchQuery,
      }),
      {
          headers: { "Content-Type": "application/json" },
      }
  )
  return result.data
}


  async function getLocations(signal) {
    setIsLoading(true)
    const result = await axiosPrivate.get(`Location/GetAll`, {
        signal: signal,
    })
    return result.data.location
}
  useEffect(() => {
    const controller = new AbortController()
    fetchOnLoadData(controller.signal)
  }, [])

  const fetchOnLoadData = (signal) => {
    getLocations(signal)
    .then((res) => {
        setAllLocations(res)
        console.log(res)
    })
    .catch((err) => {
        if (err.message != "canceled") {
            console.log(err)
        }
    }).finally(() => {
        setIsLoading(false);
      });
      getPetTypes(signal)
      .then((res) => {
          setPetTypes(res)
      })
      .catch((err) => {
          if (err.message != "canceled") {
              console.log(err)
          }
      })
  }

  const getSizeData = (id) => {
    getSizes(id)
        .then((res) => {
            setPetSizes(res?.petSize)
        })
        .catch((err) => {
            console.log(err)
            console.log("Something went wrong!")
        })
}
const getBreedData = (id) => {
  getBreedByPetType(id)
      .then((res) => {
          setPetBreeds(res?.petBreed)
      })
      .catch((err) => {
          console.log(err)
          console.log("Something went wrong!")
      })
}

const getServiceData = (id) => {
getServicesByPetType(id)
    .then((res) => {
        setPetServices(res?.serviceItemsLibrary)
    })
    .catch((err) => {
        console.log(err)
        console.log("Something went wrong!")
    })
}
const getEmployeeData = (id) => {
getEmployeeByLocationID(id)
    .then((res) => {
        setLocationEmployees(res)
    })
    .catch((err) => {
        console.log(err)
        console.log("Something went wrong!")
    })
}

  const handleSearch = () => {
    fetchData();
  };


  const handlePageChange = (event, value) => {
    setFormData((prev) => ({ ...prev, PageNumber: value }));
  };

  const handleCheckboxChange = (imagePath) => {
    setSelectedImages((prev) => {
      if (prev.includes(imagePath)) {
        return prev.filter((img) => img !== imagePath);
      } else {
        return [...prev, imagePath];
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [formData.PageNumber]);

  const handlePost = () => {
    alert('Selected Images: ' + selectedImages.join(', '));
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
   
    if(selectedImages.length < 1){
        toast.warning("Select images to proceed!") 
    }else{
        setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    handleClose();
    selectedImages.forEach((imagePath) => {
      const imageUrl = `${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${imagePath}`;
      const link = document.createElement('a');
      link.href = imageUrl;
      link.target = '_blank'; 
      link.download = imagePath.split('/').pop(); 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

const handleShareOnFacebook = () => {
  handleClose();
  if(selectedImages.length > 1){
   setIsOpenShareFBModal(true)
  }else{
      const imageUrl = `${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${selectedImages[0]}`;
      const facebookShareUrl = `https://www.facebook.com/dialog/share?app_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&display=popup&href=${encodeURIComponent(imageUrl)}`;
      window.open(facebookShareUrl, '_blank', 'width=600,height=400');
  }

};

const shareOnFacebook = () => {
  handleClose();

  let images:any = []
  selectedImages.forEach((imagePath) => {
    const imageUrl = `${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${imagePath}`;
    images.push(
        imageUrl,
    )
  });
  console.log("images", images)
  uploadAndPost(images)
};

const uploadPhoto = async (pageId, url, accessToken) => {
  try {
    const response = await axios.post(
      `https://graph.facebook.com/v20.0/${pageId}/photos`,
      {
        url: url,
        published: false, 
        access_token: accessToken,
      }
    );
    return response.data.id;
  } catch (error) {
    console.error('Error uploading photo:', error);
    setIsLoading(false)
    setUploading(false)
    throw error;
  }
};

const uploadAndPost = async (urls) => {
  setIsLoading(true)
  setUploading(true)
  try {
    const mediaFbids = await Promise.all(
      urls.map((url) => uploadPhoto(pageId, url, accessToken))
    );
    const attachedMedia = mediaFbids.map((id) => ({ media_fbid: id }));
    const postResponse = await axios.post(
      `https://graph.facebook.com/v20.0/${pageId}/feed`,
      {
        message: msg,
        attached_media: attachedMedia,
        access_token: accessToken,
      }
    );
    console.log('Post created:', postResponse.data);
    toast.success("Post created successfully!")
    setIsOpenShareFBModal(false)
    setSelectedImages([])
    setAfterChecked(false)
    setAfterChecked(false)
    setIsLoading(false)
    setUploading(false)
  } catch (error) {
    console.error('Error creating post:', error);
    setIsLoading(false)
    setUploading(false)
  }
};

const handleBeforeChange = () => {
  setSelectAll(false)
  setSelectedImages([])
  if (beforeChecked) {
    const remainingImages = selectedImages.filter((img) =>
      !petData.some((pet) => pet.BeforeImgPath === img)
    );
    setSelectedImages(remainingImages);
  } else {
    const beforeImages = petData
      .filter((pet) => pet.BeforeImgPath)
      .map((pet) => pet.BeforeImgPath);
    setSelectedImages([...selectedImages, ...beforeImages]);
  }
  setBeforeChecked(!beforeChecked); 
};

const handleAfterChange = () => {
  setSelectAll(false)
  setSelectedImages([])
  if (afterChecked) {
    const remainingImages = selectedImages.filter((img) =>
      !petData.some((pet) => pet.AfterImagePath === img)
    );
    setSelectedImages(remainingImages);
  } else {
    const afterImages = petData
      .filter((pet) => pet.AfterImagePath)
      .map((pet) => pet.AfterImagePath);
    setSelectedImages([...selectedImages, ...afterImages]);
  }
  setAfterChecked(!afterChecked); 
};


const resetFilters = () => {
  setPageSize("6")
  setFormData({
    FirstName: "",
    LastName: "",
    PetName: "",
    LocationID: null,
    FromDate: moment().subtract(1, 'months').format("YYYY-MM-DD"),
    ToDate: moment().format("YYYY-MM-DD"),
    PageNumber: 1,
    PageSize: "6",
    EmployeeID: null,
    ServiceID: null,
    PetBreedID: null,
    PetTypeID: null,
  }) as any;

  console.log('Form data after reset:', formData);

  breedRef.current?.handleClear();
  serviceRef.current?.handleClear();
  typeRef.current?.handleClear();
  locationRef.current?.handleClear();
  employeeRef.current?.handleClear();
};

const selectCut = (pet) => {
  setSelectedCut(pet)
  setIsOpenCustomerModal(true)
  console.log(pet)
}

const handleCustomerSearch = (value) => {
  setSearchValue(value);
};

const debouceSearch = useMemo(() => {
  return debouce(handleCustomerSearch, 500);
}, []);

useEffect(() => {
  if (searchValue !== "") {
    fetchCustomerData(searchValue);
    
  } else {
    setSearchData([]);
    setCustomerFilteredPets([])
    setSelectedCustomer("")
  }
}, [searchValue]);

const fetchCustomerData = (searchQuery) => {
  setIsLoading(true)
  getData(searchQuery)
      .then((res) => {
          setSearchData(res?.customer)
          console.log("cusss", res?.customer)
          setCustomerFilteredPets([])
          setSelectedCustomer("")
          setIsLoading(false)
      })
      .catch((err) => {
          console.log(err)
          setIsLoading(false)

          toast.error("Something went wrong!")
          setSearchData([])
      })
}

useEffect(()=>{
  if(isOpenCustomerModal === true){
    setSelectedCustomer("")
    setSearchData([])
    setSearchValue("")
    setCustomerFilteredPets([])
  }
},[isOpenCustomerModal])


const selectCustomer = (index) =>{
  const filteredData = searchData[index]?.customerPet.filter(item => item.numPetBreedID === selectedCut?.numBreedID);
  if (filteredData.length === 0) {
    toast.warning("You cannot select this cut because you do not have a pet of the selected breed.")
  }else{
    setCustomerFilteredPets(filteredData)
    setSelectedCustomer(index)
  }
}

const saveMultiSelection = () => {
  const filteredData = customerFilteredPets.filter(item => item.isSelected === true);
  if(filteredData.length > 0){

          Swal.fire({
          title: 'Confirm Selection',
          text: 'Are you sure you want to select this cut for your pets next groom?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          customClass: {
            container: 'swal-overlay-container',
            popup: 'swal-popup',
          },
          didOpen: () => {
            const swalOverlay:any = document.querySelector('.swal-overlay-container');
            const swalPopup:any = document.querySelector('.swal-popup');
            if (swalOverlay) {
              swalOverlay.style.zIndex = '9999'; 
            }
            if (swalPopup) {
              swalPopup.style.zIndex = '10000'; 
            }
          }
        }).then((result) => {
          if (result.isConfirmed) {

            const petList:any = [];
            filteredData.forEach(pet => {
                petList.push({ petID: pet.numPetID });
            });
                  let payload = [
                    {
                      "numCustomerID": searchData[selectedCustomer]?.numCustomerID,
                      "numLocationID": selectedCut?.numLocationID,
                      "numAppointmentID": selectedCut?.numAppointmentID,
                      "serviceID": selectedCut?.numServiceItemID,
                      "numPetTypeID": selectedCut?.numPetTypeID,
                      "numPetBreedID": selectedCut?.numBreedID,
                      "refNo": selectedCut?.ReferenceNumber,
                      "petList":petList
                    }
                  ]

                  console.log(payload)
                  axios.post(`${process.env.REACT_APP_API_URL}Schedule/PopulatePreferredCutsFromGallery`, payload)
                    .then(function (response) {
                    console.log("app_success", response);
                    if(response.status === 200){
                        toast.success("Selection saved successfully!");
                        setIsOpenCustomerModal(false)
                    }
                    })
                    .catch(function (error) {
                        console.log(error);
                        setIsLoading(false)
                    });
             
          }else if(result.dismiss){
              setSelectedCut("")
          } 
        });
  }else{
    toast.warning("Select pets to continue!")
  }
}

const shareMultiImagesOnFB = () => {
  if(pageId === ""){
    toast.warning("Please select a Location/Page to share images")
  }else{
    shareOnFacebook()
  }
}

const showLoadingSwal = () => {
  Swal.fire({
    title: 'Please wait...',
    text: "We're creating your post on Facebook",
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
      Swal.showLoading(); 
    }
  });
};

useEffect(()=>{
  if(uploading === true){
    showLoadingSwal()
  }else{
    Swal.close();
  }
},[uploading])

useEffect(()=>{
  if(shareLocation !== null){
    const filteredLoc = tempLocations.filter(loc => loc.value === shareLocation);
    setPageId(filteredLoc[0]?.pageID)
  }
},[shareLocation])


const handlePageCountChange = (event) => {
  const newSize = event.target.value;
  setPageSize(newSize);
};

useEffect(()=>{
  if(pageSize !== ""){
    const pageData = {...formData}
    pageData.PageSize = pageSize
    setFormData(pageData)
    fetchData()
  }
},[pageSize])

const sendImages = () => {
  if(selectedCustomer !== ""){
    setIsLoading(true)
    console.log("se;e", selectedCut)
    let payload = [{
      BeforeImage : `${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${selectedCut?.BeforeImgPath}`,
      AfterImage : `${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${selectedCut?.AfterImagePath}`,
      numCustomerID : searchData[selectedCustomer]?.numCustomerID,
      numEmployeeID : auth?.employee?.numEmployeeID
    }]
    console.log("payload-",payload)

    axios.post(`${process.env.REACT_APP_API_URL}Schedule/PopulatePreferredCutsLogForInstantNotification`, payload)
    .then(function (response) {
    console.log("app_success", response);
    if(response.status === 200){
        toast.success("Sent successfully!");

        let imageAttachments = `
        <p>Click <a href="${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${selectedCut?.BeforeImgPath}" target="_blank" rel="noopener noreferrer" download="before_image.jpg">here</a> to view the before image.</p>
        <p>Click <a href="${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${selectedCut?.AfterImagePath}" target="_blank" rel="noopener noreferrer" download="after_image.jpg">here</a> to view the after image.</p>
      `;
        //Notification
        let notificationPostBody = {
            notificationType: "success",
            subject: "Employee Suggestion: Pet Cut",
            notificationMessage: imageAttachments,
            locationID:  auth?.currentLocation?.value,
            locationName: auth?.currentLocation?.label,
            recipientType: "C",
            recipientID: searchData[selectedCustomer]?.numCustomerID,
            recipientName: searchData[selectedCustomer]?.varCustomerFirstName +" "+ searchData[selectedCustomer]?.varCustomerLastName,
            isSeen: false,
            isPushed: true
        }
        sendOutNotification(notificationPostBody)
        setIsLoading(false)
    }
    })
    .catch(function (error) {
        console.log(error);
        setIsLoading(false)
    });

  }else{
    toast.warning("Select a customer first!")
  }
}

console.log("rescus", searchData[selectedCustomer])

  return (
    <div style={{marginLeft:"10px"}}>
      <div style={{ marginTop: "12px", display: "flex", flexDirection: "column", gap: "10px" }}>
      {/* First Row */}
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        <TextField
          label="From Date"
          name="FromDate"
          type="date"
          value={formData.FromDate}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
          style={{ width: "250px" }}
          size='small'
        />
        <TextField
          label="To Date"
          name="ToDate"
          type="date"
          value={formData.ToDate}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
          style={{ width: "250px" }}
          size='small'
        />
        <div style={{ width: '250px' }}>
          {petTypes && (
            <AdvancedDropDown
              data={petTypes.map((i) => ({ label: i.varPetTypeName, value: i.numPetTypeID }))}
              onChange={(e) => {
                handleInputChange({ name: 'PetTypeID', value: e.value });
                setPetType(e.value);
                getSizeData(e.value);
                getBreedData(e.value);
                getServiceData(e.value);
                setPetBreeds([]);
                setPetSizes([]);
                setPetServices([]);
              }}
              ref={typeRef}
              placeHolder="Pets Type"
              isDisabled={petTypes.length > 0 ? undefined : true}
            />
          )}
        </div>
        <div style={{ width: '250px' }}>
          {petServices && (
            <AdvancedDropDown
              data={petServices.map((i) => ({ label: i.varServiceItemName, value: i.numServiceItemID }))}
              onChange={(e) => {
                handleInputChange({ name: 'ServiceID', value: e.value });
              }}
              ref={serviceRef}
              placeHolder="Service"
              isDisabled={petServices.length > 0 ? undefined : true}

            />
          )}
        </div>
        <div style={{ width: '250px' }}>
          {petBreeds && (
            <AdvancedDropDown
              data={petBreeds.map((i) => ({ label: i.varPetBreedName, value: i.numPetBreedID }))}
              onChange={(e) => {
                handleInputChange({ name: 'PetBreedID', value: e.value });
              }}
              ref={breedRef}
              placeHolder="Breed"
              isDisabled={petBreeds.length > 0 ? undefined : true}
            />
          )}
        </div>
      </div>

      {/* Second Row */}
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        <div style={{ width: '250px' }}>
          {allLocations && (
            <AdvancedDropDown
              data={allLocations.map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
              onChange={(e) => {
                handleInputChange({ name: 'LocationID', value: e.value });
                getEmployeeData(e.value);
              }}
              ref={locationRef}
              placeHolder="Location"
              isDisabled={allLocations.length > 0 ? undefined : true}
            />
          )}
        </div>
        <div style={{ width: '250px' }}>
          {locationEmployees && (
            <AdvancedDropDown
              data={locationEmployees.map((i) => ({ label: i.varEmployeeFirstName + " " + i.varEmployeeLastName, value: i.numEmployeeID }))}
              onChange={(e) => {
                handleInputChange({ name: 'EmployeeID', value: e.value });
              }}
              ref={employeeRef}
              placeHolder="Employee"
              isDisabled={locationEmployees.length > 0 ? undefined : true}
            />
          )}
        </div>
        <TextField
          label="Customer Name"
          name="FirstName"
          value={formData.FirstName}
          onChange={handleInputChange}
          style={{ width: "250px" }}
          size='small'
        />
        <TextField
          label="Pet Name"
          name="PetName"
          value={formData.PetName}
          onChange={handleInputChange}
          style={{ width: "250px" }}
          size='small'
        />
        <TextField
          label="Ref: Number"
          name="LastName"
          value={formData.LastName}
          onChange={handleInputChange}
          style={{ width: "250px" }}
          size='small'
        />
      </div>
    </div>

    <div style={{display:"flex", marginTop:"10px", marginBottom:"10px"}}>
        <div>      
              <FormControlLabel
                control={<Checkbox checked={beforeChecked} onChange={handleBeforeChange} />}
                label={<Typography style={{ fontSize: '12px', fontWeight:"600" }}>Before Images</Typography>}
                
              />
              <FormControlLabel
                control={<Checkbox checked={afterChecked} onChange={handleAfterChange} />}
                label={<Typography style={{ fontSize: '12px', fontWeight:"600" }}>After Images</Typography>}
              />
        </div>
        <div style={{display:"flex", gap:"10px"}}>
          <Button variant="contained" color='primary'  disabled={isLoading} onClick={handleSearch} text='Search'/>
          <Button variant="outlined" color='default' disabled={isLoading} onClick={resetFilters} text='Clear'/>
          <>
            <MUIButton
            variant="contained"
            color="success"
            onClick={handleClick}
            startIcon={<ShareIcon />}
            disabled={isLoading}
            >
            Share
            </MUIButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleShareOnFacebook}>
              <ListItemIcon>
                <FacebookIcon />
              </ListItemIcon>
              <Typography variant="inherit">Share on Facebook</Typography>
            </MenuItem>
            <MenuItem onClick={handleDownload}>
              <ListItemIcon>
                <DownloadIcon />
              </ListItemIcon>
              <Typography variant="inherit">Download</Typography>
            </MenuItem>
          </Menu>
        </>
        </div>
    </div>

    <Modal open={isOpenCustomerModal}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>Select Customer</p>
              <CloseButton onClick={() => setIsOpenCustomerModal(false)} />
          </div>
          <div style={{display:"flex", justifyContent:"center"}}>
            <p style={{fontWeight:"600", margin:"0", backgroundColor:"#e8e8e8", padding:"5px", borderRadius:"8px"}}>Type: <span>{selectedCut?.varPetTypeName}</span>&nbsp;|&nbsp;Breed: <span>{selectedCut?.varBreedName}</span></p>
          </div>
          <div style={{width:"100%"}}>
          {
              <div style={{ height: "100%", display: "flex", alignItems: "center", gap: "0.3rem", padding: "2px .5rem", background: "#fff" }}>
                  <Search color={"disabled"} />
                  <Input
                      className={styles.searchInput}
                      onChange={(e) => {
                          debouceSearch(e.target.value)
                      }}
                      placeholder={"Search"}
                      style={{ width: "100%" }}
                      ref={searchBoxRef}
                  />
              </div>
          }
          <div style={{display:"flex"}}>
          <div style={{ overflow: "scroll", height: "calc(70vh - 210px)", padding: "0 0.2rem", marginTop:"10px", width:"400px" }}>
            {(isLoading)? <p style={{color:"gray"}}>Loading...</p> :
            searchData.length > 0 ? (
                searchData.map((customer, index) => {
                    return (
                        <div key={index} onClick={() =>{selectCustomer(index)}} className={(selectedCustomer === index)? styles.card_active : styles.card}>
                            <div
                                style={{
                                    padding: "0 0 .5rem 0",
                                    borderBottom: "1px solid #F3F4F6",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: "1rem",
                                }}
                            >
                                <Avatar
                                    className={"medium"}
                                    img={
                                        customer?.varCustomerImagePath === null
                                            ? ""
                                            : `${process.env.REACT_APP_MEDIA_URL}directories/customers/${customer?.varCustomerImagePath}`
                                    }
                                    isVip={customer?.bitIsVip}
                                />
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "0.2rem" }}>
                                    <div>
                                        <p
                                            style={{ margin: "0px", fontWeight: "600", fontSize: "14px", textTransform: "capitalize" }}
                                        >{`${customer?.varCustomerFirstName} ${customer?.varCustomerLastName}`}</p>
                                    </div>

                                    <div>
                                        <LocationChip
                                            label={customer?.varCustomerDefaultLocation === undefined ? "" : Truncate(customer?.varCustomerDefaultLocation, 19)}
                                            shape="square"
                                            icon={<Place style={{ fontSize: "13px" }} />}
                                            customStyle="BlueFilled"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div style={{ borderBottom: "1px solid #F3F4F6", display: "flex", justifyContent: "space-between", padding: "0.5rem 0" }}>
                                <div style={{ width: "100%" }}>
                                    <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Mobile</p>
                                    <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>{formatPhone(customer?.varCustomerMobilePhone)} </p>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Home</p>
                                    <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>{formatPhone(customer?.varCustomerHomePhone)} </p>
                                </div>
                            </div>

                        </div>
                    )
                })
                ) : searchValue != "" ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <p>No results found</p>
                    </div>
                ) : null}
               
              </div>
              <div style={{display:"flex", flexDirection:"column", gap:"10px", width:"400px", maxHeight:"300px", overflowY:"scroll"}}>
                {(selectedCustomer === "")? <div></div> :
                (isLoading)? <p style={{color:"gray"}}>Loading...</p> :
                  customerFilteredPets.length > 0
                    ? customerFilteredPets.map((pet, index) => {
                          return (
                              <div>
                                  <div style={{display:"flex", justifyContent:"space-between"}}>
                                      <div style={{ display: "flex", alignItems: "center", gap: "1rem", padding: "0.5rem 0" }}>
                                          <div style={{ padding: "0.5rem 0.8rem", backgroundColor: "#EFF5FF", borderRadius: "5px", fontWeight: "600" }}>
                                              S
                                          </div>
                                          <div>
                                              <p style={{ margin: "0", fontWeight: "600" }}>{pet?.varPetName}</p>
                                              <p style={{ color: "gray", margin: "0", fontSize: "11px" }}>{pet?.varPetBreed}</p>
                                          </div>
                                      </div>
                                      <Checkbox
                                        style={{ padding: '0' }}
                                        key={index}
                                        checked={!!customerFilteredPets[index]?.isSelected}
                                        onChange={() => {
                                          const filteredPets = [...customerFilteredPets];
                                          filteredPets[index].isSelected = !filteredPets[index]?.isSelected;
                                          setCustomerFilteredPets(filteredPets);
                                        }}
                                      />
                                  </div>
                              </div>
                          )
                      })
                    : null}
              </div>
          </div>
          <div style={{display:"flex", justifyContent:"flex-end"}}>
            <div style={{display:"flex", gap:"10px"}}>
              <Button variant="contained" color='primary' disabled={isLoading} onClick={saveMultiSelection} text='Save Changes'/>
              <MUIButton
                variant="contained"
                color="success"
                onClick={sendImages}
                startIcon={<MobileScreenShare />}
                disabled={isLoading}
                >
                Send
              </MUIButton>
            </div>
          </div>
          </div>
        </div>
    </Modal>

      <Modal open={isOpenShareFBModal}>
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
            <div style={{ padding: "20px" }}>
                <div style={{ width: "500px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <p style={{ fontWeight: 600, fontSize: "20px", width: "100%" }}>Sharing Options</p>
                    <CloseButton onClick={() => setIsOpenShareFBModal(false)} />
                </div>
            </div>
                 
              <div style={{display:"flex", gap:"5px", alignItems:"center"}}>
                <p style={{color:"#1976d2", fontWeight:"500", margin:"0"}}>{selectedImages.length} Images have been selected</p> 
                <InfoOutlined style={{color:"#1976d2"}}/>
              </div>
              
              <div style={{ backgroundColor: "#fff", width: "100%", display:"flex", flexDirection:"column", gap:"10px" }}>
              <div style={{ width: '100%' }}>
                {tempLocations && (
                  <AdvancedDropDown
                    data={tempLocations.map((i) => ({ label: i.name, value: i.value }))}
                    onChange={(e) => {
                      setShareLocation(e.value)
                    }}
                    ref={shareLocationRef}
                    placeHolder="Select Location"
                    isDisabled={tempLocations.length > 0 ? undefined : true}
                    passByID={true}
                    value={shareLocation}
                  />
                )}
              </div>
              <TextField
                  multiline
                  fullWidth
                  minRows={10} 
                  maxRows={Infinity} 
                  variant="outlined"
                  sx={{ height: '100%' }}
                  value={msg}
                  onChange={(e)=>{setMsg(e.target.value)}}
                />
              </div>
              <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem" }}>
               <MUIButton
                  variant="contained"
                  color="primary"
                  onClick={()=>{shareMultiImagesOnFB()}}
                  startIcon={<FacebookIcon/>}
                  disabled={isLoading}
                  >
                  Share
              </MUIButton>
              </div>
          </div>
      </Modal>

      <Grid container spacing={2}>
        {
        (petData === undefined)? <p style={{color:"gray", fontSize:"13px", marginLeft:"20px", marginTop:"10px"}}>No images found</p> :
        (petData.length < 1)? <p style={{color:"gray", fontSize:"13px", marginLeft:"20px" , marginTop:"10px"}}>No images found</p> :
        petData.map((pet) => (
          <Grid item xs={2} key={pet?.numAppointmentID} style={{ position: 'relative', padding:"10px", marginTop:"10px" }}>
            {pet?.BeforeImgPath && (
              <div style={{ position: 'relative' }}>
                <Checkbox
                checked={selectedImages.includes(pet?.BeforeImgPath)}
                onChange={() => handleCheckboxChange(pet?.BeforeImgPath)}
                style={{
                    position: 'absolute',
                    top: "2px",
                    right: "3px",
                    zIndex: 1,
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    padding: '0px',
                }}
                />
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${pet?.BeforeImgPath}`}
                  alt="Before"
                  style={{ width: '100%', height: '280px', marginBottom: '5px' , objectFit:"cover", borderRadius:"4px"  }}
                />
              </div>
            )}
            {pet?.AfterImagePath && (
              <div style={{ position: 'relative' }}>
                <Checkbox
                checked={selectedImages.includes(pet?.AfterImagePath)}
                onChange={() => handleCheckboxChange(pet?.AfterImagePath)}
                style={{
                    position: 'absolute',
                    top: "2px",
                    right: "3px",
                    zIndex: 1,
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    padding: '0px',
                }}
                />
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${pet?.AfterImagePath}`}
                  alt="After"
                  style={{ width: '100%', height: '280px', marginBottom: '5px', objectFit:"cover", borderRadius:"4px" }}
                />
              </div>
            )}
            <div style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
              <p style={{color:"black", fontSize:"14px", textAlign:"center"}}>{pet?.ReferenceNumber}</p>
              <p style={{color:"gray", fontSize:"14px", textAlign:"center"}}>{pet?.varBreedName}</p>
              <Button variant="outlined" color='default'  disabled={isLoading} onClick={()=>{selectCut(pet)}} text='Select'/>
            </div>

          </Grid>
        ))}
      </Grid>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={totalPages}
          page={formData.PageNumber}
          onChange={handlePageChange}
        />
      </div>
      <FormControl variant="outlined" size="small" style={{ minWidth: 80 }}>
        <InputLabel id="page-size-label">Page Size</InputLabel>
        <Select
          labelId="page-size-label"
          value={pageSize}
          onChange={handlePageCountChange}
          label="Page Size"
        >
          {[6, 10, 25, 50, 100].map(size => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>

    </div>
  );
};

export default NewBookingInquiry;











