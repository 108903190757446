
import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Collapse, IconButton, TablePagination, MenuItem, Select, TextField, Button, Tooltip, Chip
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, PhoneIphone, Home, Work, Sms, Email } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import { Modal } from '../../../components/components';
import { CloseButton } from '../../../components';

const LogHistory = (props: {
    isOpenLogHistoryModal;
    setIsOpenLogHistory: CallableFunction;
}) => {


    const [data, setData] = useState([]) as any;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [status, setStatus] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchData = async () => {
    const params = {
      pageNumber: page + 1,
      pageSize: rowsPerPage,
      ...(fromDate && { FromDate: moment(fromDate).format('YYYY-MM-DD') }),
      ...(toDate && { ToDate: moment(toDate).format('YYYY-MM-DD') }),
      ...(status && { status }),
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}CallCenter/GetCommunicationFaliureLog`, { params });
      setData(response.data.data);
      setTotalRecords(response.data.totalRecords);
      console.log("logData", response.data.data)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, fromDate, toDate, status]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(()=>{ if(props.isOpenLogHistoryModal === true){
    fetchData()
  }},[props.isOpenLogHistoryModal])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function CollapsibleRow({ row }) {
    const [open, setOpen] = useState(false);
  
    const renderCommunicationMethodIcon = (methodCode) => {
      switch (methodCode) {
        case 'C':
          return <Tooltip title={<p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Mobile Phone</p>}><PhoneIphone color="success" /></Tooltip>;
        case 'H':
          return <Tooltip title={<p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Home Phone</p>}><Home color="secondary" /></Tooltip>;
        case 'W':
          return <Tooltip title={<p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Work Phone</p>}><Work color="warning" /></Tooltip>;
        case 'S':
          return <Tooltip title={<p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>SMS</p>}><Sms color="primary" /></Tooltip>;
        case 'E':
          return <Tooltip title={<p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Email</p>}><Email color="action" /></Tooltip>;
        default:
          return null;
      }
    };
  
    const renderStatusChip = (status) => {
      return status === 'S' ? (
        <Chip label="Success" color="success" />
      ) : (status === "P")? <Chip label="Pending" color="info" /> : (
        <Chip label="Failed" color="error" />
      );
    };
  
    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell>{row.MessageID}</TableCell>
          <TableCell>{row.Notes}</TableCell>
          <TableCell>{renderCommunicationMethodIcon(row.CommunicationMethod)}</TableCell>
          <TableCell>{row.ContactDetails}</TableCell>
          <TableCell>{row.EmployeeName}</TableCell>
          <TableCell>{renderStatusChip(row.Status)}</TableCell>
          <TableCell>{moment(row.AttemptedAt).format('YYYY-MM-DD HH:mm')}</TableCell>

        </TableRow>
        <TableRow>
  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Table size="small" aria-label="details">
        <TableHead>
          <TableRow style={{ backgroundColor: '#e4e4e4' }}>
            <TableCell>ID</TableCell>
            <TableCell>Detail</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Failure Reason</TableCell>
            <TableCell>Attempted At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(row.DetailRecords) && row.DetailRecords.map((detail) => (
            <TableRow key={detail.ID} style={{ backgroundColor: '#f5f5f5' }}>
              <TableCell>{detail.ID}</TableCell>
              <TableCell>{detail.ContactDetails}</TableCell>
              <TableCell>{renderCommunicationMethodIcon(detail.CommunicationMethod)}</TableCell>
              <TableCell>{renderStatusChip(detail.Status)}</TableCell>
              <TableCell style={{ whiteSpace: 'normal', wordBreak: 'break-word', maxWidth: '300px' }}>
                {detail.FailureReason || '-'}
              </TableCell>
              <TableCell>{moment(detail.AttemptedAt).format('YYYY-MM-DD HH:mm')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Collapse>
  </TableCell>
</TableRow>

      </>
    );
  }
  

    return (
        <Modal open={props.isOpenLogHistoryModal} >
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column',  gap: '1rem', padding: "0 1rem 0 1rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                    <p style={{ fontSize: "24px", fontWeight: "600", color: "#192252" }}>Communication History</p>
                    <CloseButton onClick={() => props.setIsOpenLogHistory(false)} />
                </div>
                <TableContainer component={Paper} style={{ width: "80vw", margin: "0 auto" }}>
                    <div style={{ display: 'flex', gap: '1rem', padding: '1rem' }}>
                        <TextField
                            label="From Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                        />
                        <TextField
                            label="To Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                        />
                        <Select
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            displayEmpty
                        >
                            <MenuItem value=""><em>All</em></MenuItem>
                            <MenuItem value="P">Pending</MenuItem>
                            <MenuItem value="S">Success</MenuItem>
                            <MenuItem value="C">Canceled</MenuItem>
                            <MenuItem value="F">Failed</MenuItem>
                            <MenuItem value="R">Retrying</MenuItem>
                        </Select>
                        <Button variant="contained" color="primary" onClick={() => fetchData()}>Search</Button>
                    </div>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Message ID</TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell>Communication Method</TableCell>
                                <TableCell>Contact Detail</TableCell>
                                <TableCell>Customer Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Attempted At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <CollapsibleRow key={row.MessageID} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={totalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>

            </div>
        </Modal>
    );
}

export default LogHistory;